import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import DefaultImage from '../../assets/DefaultImage.svg'
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./ImageSliderMobileStyle.css";
import { FreeMode, Navigation, Thumbs } from "swiper";

export default function App(Props) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const generateThumbnailUrl = (videoUrl) => {
        // Replace the video file extension with '.jpg' for the thumbnail image
        return videoUrl.replace(/\.[^.]+$/, '.jpg');
    };
    

    const [mediaItems, setMediaItems] = useState(Props.data.map(item => {
        return {
            type: /\.(mp4|webm|ogg)$/i.test(item.image_url) ? 'video' : 'image',
            url: item.image_url
        }
    }));
    console.log(mediaItems)
    useEffect(() => {
        setMediaItems(mediaItems.length ? mediaItems : [{ type: 'image', url: DefaultImage }]);
    }, [mediaItems]);
    return (
        <>
            <Swiper
                style={{
                    "height": "300px",
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                }}
                loop={true}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2"
            >
                {mediaItems.map((item, index) => (
                    <SwiperSlide key={index}>
                        {item.type === 'image' ? (
                            <img src={item.url} alt={`Image ${index}`} />
                        ) : (
                            <video controls>
                                <source src={item.url} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}
                    </SwiperSlide>
                ))}
            </Swiper>
            <Swiper
                style={{
                    "height": "100px",
                    "background": "white"
                }}
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={10}
                slidesPerView={5}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper"
            >
                {mediaItems.map((item, index) => (
                    <SwiperSlide key={index}>
                        {item.type === 'image' ? (
                            <img src={item.url} alt={`Image ${index}`} />
                        ) : (
                            <div className="video-container">
                                {/* <img src={generateThumbnailUrl(item.url)} /> */}
                                <div className="play-button-overlay"></div>
                            </div>
                        )}
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
}
