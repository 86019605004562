import React, { useEffect, useState, useRef } from 'react';
import profile_ring from '../../../assets/profile_ring.svg';
import Aerrow from '../../../assets/Down_Aerrow.svg';
import SettingsLogo from '../../../assets/Settings_logo.svg';
import Securitylogo from '../../../assets/Security_logo.svg';
import SignOutLogo from '../../../assets/SignOut_Logo.svg';
import CheckMark from '../../../assets/CheckMark.svg';
import EditPen from '../../../assets/Edit_pen.svg';
import countries from 'countries-list';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { useDispatch, useSelector } from 'react-redux';
import { SetProfileStatus, setLanguage, SetUserData } from '../../Store/index';
import { SetUserprofileupdate, DeleatUser } from '../../../server/index';

const Profile = (props) => {
    const [RawData, SetRawData] = useState(props.data);
    const [hideCam, setHideCam] = useState(false);
    useEffect(() => {
        SetRawData(props.data)
    }, [props.data]);
    const LanguageKey = {
        chi: '中',
        eng: '英'
    }
    const findKeyByValue = (value) => {
        let convertdata = ''
        if (/^[A-Za-z]+$/.test(value)) {
            convertdata = value.toLowerCase()
        } else {
            convertdata = value
        }
        for (const key in LanguageKey) {
            if (LanguageKey[key] === convertdata) {
                return key;
            }
        }
        for (const key in LanguageKey) {
            if (key === convertdata) {
                return convertdata;
            }
        }
    };
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);  // State for delete confirmation modal
    const [UpdateName, SetUpdateName] = useState('');
    const [Not, setNot] = useState(false);
    const [defaultProfile, setDefaultProfile] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [selectedCountry, setCountry] = useState('en');
    const [Name, SetName] = useState(false);
    const dispatch = useDispatch();
    const profiles = useSelector(state => state.storeContent.UserData);
    const videoRef = useRef(null);
    const mediaStreamRef = useRef(null);
    const fileInputRef = useRef(null);
    useEffect(() => {
        SetRawData(props.data);
    }, [props.data]);
    const handleTakePhoto = (dataUri) => {
        const blob = dataUriToBlob(dataUri);
        const file = new File([blob], 'captured_image.jpg', { type: 'image/jpeg' });
        patchImage(file, profiles)
        setHideCam(!hideCam)
    };
    const dataUriToBlob = (dataUri) => {
        const byteString = atob(dataUri.split(',')[1]);
        const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };
    const patchImage = async (image, profiles) => {
        let header = {
            headers: {
                Authorization: `Token ${profiles?.Authorization}`
            }
        }
        const formData = new FormData();
        formData.append('profile_image', image);
        try {
            const { data } = await SetUserprofileupdate(header, formData);
            dispatch(SetUserData({ ...profiles, ...data }));
            localStorage.setItem(`UserData`, JSON.stringify({ ...profiles, ...data }));
        } catch (error) { }
    }
    const Close = () => {
        dispatch(SetProfileStatus())
    }
    const toggleCheckbox = () => {
        let header = {
            headers: {
                Authorization: `Token ${profiles?.Authorization}`,
                'Content-Type': 'application/json'
            }
        }
        try {
            const { data } = SetUserprofileupdate(header,
                {
                    is_notification_allowed: !Not,
                }
            )
            setNot(!Not)
        } catch (error) {

        }
    }
    useEffect(() => {
        if (profiles && profiles?.Authorization) {
            setNot(profiles.is_notification_allowed)
        }
    }, [profiles]);

    const countryList = Object.entries(countries.countries).map(([code, country]) => {
        const { name, phone, languages } = country;
        return {
            name,
            flag: `https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/${code.toLowerCase()}.png`,
            phoneCode: phone,
            Lan: languages
        };
    });
    const filteringCountry = () => {
        return countryList.filter((o) => o.Lan.includes(selectedCountry));
    };
    const IconData = (data) => {
        return data === 'English' ? filteringCountry()[0]?.flag : data === 'Settings' ? SettingsLogo : data === 'Security & Privacy' ? Securitylogo : SignOutLogo;
    };
    const checker = (prop) => {
        dispatch(SetProfileStatus());
        Close()
    }
    const findSelectedLan = (Props) => {
        localStorage.setItem(`Language`, findKeyByValue(Props))
        dispatch(setLanguage(findKeyByValue(Props)))
    }
    const peration = (prop) => {
        if (prop.Tag == 'language') {
            findSelectedLan(prop.title);
            return
        }
        if (prop.tage == 'Delete') {
            setShowDeleteConfirmation(true);
        }
    };

    const confirmDelete = async () => {
        try {
            await DeleatUser({
                headers: {
                    Authorization: `Token ${profiles?.Authorization}`,
                    'Content-Type': 'application/json'
                }
            })
            dispatch(SetUserData({ "provider": "" }))
            dispatch(SetProfileStatus())
            setShowDeleteConfirmation(false);
        } catch (error) {

        }
    }
    const SaveBtn = () => {
        SetName(!Name);
        if (UpdateName.length) {
            let header = {
                headers: {
                    Authorization: `Token ${profiles?.Authorization}`,
                    'Content-Type': 'application/json'
                }
            }
            try {
                SetUserprofileupdate(header, {
                    first_name: UpdateName.split(' ')[0],
                    last_name: UpdateName.split(' ').slice(1).join(' ')
                }
                )
                dispatch(SetUserData({ ...profiles, 'first_name': UpdateName }));
                localStorage.setItem(`UserData`, JSON.stringify({ ...profiles, 'first_name': UpdateName }));
            } catch (error) {

            }
        }
    };

    const handleInputChange = (event) => {
        SetUpdateName(event.target.value);
    };

    const DropDown = (data, img) => {
        if (data.dropDown && !data.ShowDropDownData) {
            SetRawData(
                RawData.map((a) =>
                    a.title === data.title
                        ? { ...a, img: img, ShowDropDownData: true }
                        : a
                )
            );
        } else if (data.dropDown && data.ShowDropDownData) {
            SetRawData(
                RawData.map((a) =>
                    a.title === data.title
                        ? { ...a, img: img, ShowDropDownData: false }
                        : a
                )
            );
        } else if (data.title === 'Sign out') {
            localStorage.setItem(`UserData`, JSON.stringify({ "provider": "" }));
            dispatch(SetUserData({ "provider": "" }));
            props.UnableSignin(profiles?.Authorization);
            dispatch(SetProfileStatus());
        } else if (data.title === 'Security & Privacy') {
            window.location.href = data.link;
        }
    };

    const handleFileSelect = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            patchImage(selectedFile, profiles);
            setImageSrc(URL.createObjectURL(selectedFile));
            setDefaultProfile(!defaultProfile);
        }
    };

    const handleSelectImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const selectImage = () => {
        setDefaultProfile(!defaultProfile);
    };

    const Login = () => {
        props.UnableSignin(profiles?.Authorization);
    };

    const showCamara = () => {
        setHideCam(!hideCam);
        setDefaultProfile(false);
    };
    
    useEffect(() => {
        const handleBackButtonEvent = (event) => {
            event.preventDefault();
            Close();
        };
        window.addEventListener('popstate', handleBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', handleBackButtonEvent);
        };
    }, []);
    
    return (
        <div className="w-full h-full bg_blur fixed top-0 z-[12] Def-font">
            {hideCam && <div className='pt-[5%]'>
                <Camera onTakePhoto={(dataUri) => handleTakePhoto(dataUri)} />
                <button className='mt-3 p-2 bg-red-400 rounded-md' onClick={() => showCamara()}>Cancel</button>
            </div>}
            {!hideCam && (
                <div className="w-full md:w-[35%] lg:w-[45%] min-h-full absolute right-0 h-[100vh] overflow-auto bg-[#3B3F49]">
                    <div className="absolute top-[20px] right-[30px] md:right-[90px] lg:right-[130px] cursor-pointer" onClick={() => SaveBtn()}>
                        <div className="w-[30px] h-[30px] right-[5%] md:right-[5%] lg:right-[5%] z-[13] top-[3%] md:top-[4%] lg:top-[5%] z-8 rounded-full flex justify-center items-center text-xl cursor-pointer text-white bg-[#62656D]" onClick={() => Close()}>
                            <div className="flex items-center justify-center">x</div>
                        </div>
                    </div>
                    <div className="h-[308px] md:h-[300px] lg:h-[350px] w-full bg-no-repeat bg-cover center_aline pt-[10%]">
                        <div className="w-full h-[308px] md:h-[300px] lg:h-[350px] center_aline flex flex-col">
                            <div className="w-[115px] h-[115px] rounded-[95px]">
                                <div className="w-[115px] h-[115px] rounded-[95px] border-[2.38px] border-[#ffba40]/[0.27] center_aline">
                                    <div className="w-[91px] h-[91px] rounded-full">
                                        {profiles?.profile_image && <img src={profiles?.profile_image} alt="Captured" className='w-full h-full rounded-full' />}
                                        {!profiles?.profile_image && <span className='bg-[#FFBA40] w-full h-full rounded-full text-white flex justify-center items-center text-[60px]'>{profiles?.first_name ? profiles.first_name.split('')[0] : 'G'}</span>}
                                    </div>
                                </div>
                                <div className="relative bottom-[36px] left-[72px] cursor-pointer">
                                    <img className="" src={profile_ring} alt="Profile Ring" onClick={() => selectImage()} />
                                </div>
                                {defaultProfile && <div className='w-full relative z-9 rounded-md bg-[#3b3f49] border-2 border-[#ffffff26] top-[-30%] left-[30%]'>
                                    <div className='py-2 w-full border-b-2 border-[#ffffff26] text-white cursor-pointer' onClick={handleSelectImageClick}>Select Image</div>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleFileSelect}
                                        style={{ display: 'none' }}
                                        ref={fileInputRef}
                                    />
                                    <div className='py-2 w-full text-white cursor-pointer' onClick={() => showCamara()}>Take Pic</div>
                                </div>}
                            </div>
                            {!Name &&
                                <>
                                    <div className='flex justify-center items-center'>
                                        <p className="text-[38px] font-medium text-center text-white">{profiles?.given_name ? profiles.given_name : profiles?.first_name ? UpdateName ? UpdateName : profiles.first_name : 'Guest name'}</p>
                                        <div className="w-[30px] h-[30px] rounded-full flex justify-center items-center border-2 border-[#FFBA40] ml-5 cursor-pointer" onClick={() => SaveBtn()}>
                                            <img src={EditPen} alt="Edit Pen" />
                                        </div>
                                    </div>
                                </>
                            }
                            {Name &&
                                <>
                                    <div
                                        className="flex mt-3 w-[465px] h-[65px] rounded-[20px] bg-black/10 border border-[#999]/30"
                                        style={{ boxShadow: "0px 4px 8px 0 rgba(0,0,0,0.15)" }}
                                    >
                                        <input
                                            className="text-[38px] font-medium text-center text-white outline-none bg-transparent w-[90%]"
                                            type="text"
                                            defaultValue={
                                                profiles.given_name
                                                    ? profiles.given_name
                                                    : profiles.first_name
                                                        ? profiles.first_name
                                                        : "Guest name"
                                            }
                                            onBlur={handleInputChange}
                                        />
                                        <img src={CheckMark} className='cursor-pointer' alt="Check Mark" onClick={() => SaveBtn()} />
                                    </div>
                                </>
                            }
                            <p className="text-sm text-center text-[#aaa]">User since:
                                {new Date(profiles?.date_joined).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric", })}</p>
                            <p className="text-lg font-medium text-center text-[#ddd]">{profiles?.phone_number}</p>
                        </div>
                    </div>
                    <hr className="h-px mt-4 bg-gray-200 border-0 dark:bg-gray-700" />
                    {Name && <div className="absolute h-full w-full z-8 backdrop-blur-sm h-[100vh]" />}
                    <div className="flex justify-center bg-[#3B3F49] pt-20">
                        <div className="flex flex-col w-[90%] md:w-[55%] lg:w-[60%]">
                            {RawData.map((a, i) => (
                                <React.Fragment key={a.title}>
                                    {!a.ShowDropDownData && (
                                        <>
                                            <div
                                                className="h-[84px] md:h-[94px] lg:h-[124px] flex items-center justify-start cursor-pointer"
                                                onClick={() => DropDown(a, IconData(a.title))}
                                            >
                                                {a?.logo && <img className="w-[30] h-[28px]" src={IconData(a.title)} alt="Settings Logo" />}
                                                {!a?.SecondTitle && <p className="text-xl text-left text-[#aaa] pl-[20px] pr-[10px] cursor-pointer">{a?.title}</p>}
                                                {a?.SecondTitle && (
                                                    !profiles?.id ? (
                                                        <p className="text-xl text-left text-[#aaa] pl-[20px] pr-[10px] cursor-pointer" onClick={() => checker()}>{a?.SecondTitle}</p>
                                                    ) : (
                                                        <p className="text-xl text-left text-[#aaa] pl-[20px] pr-[10px] cursor-pointer" onClick={() => Login()}>{a?.title}</p>
                                                    )
                                                )}
                                                {a.dropDown && <img className="" src={Aerrow} alt="Arrow" />}
                                            </div>
                                            {i + 1 !== RawData.length && <div className="w-[100%] h-0.5 opacity-50 bg-[#ffffff26]"></div>}
                                        </>
                                    )}
                                    {a.ShowDropDownData && (
                                        <div className="bg-[#4D5763]">
                                            <div
                                                className="h-[44px] md:h-[64px] lg:h-[78px] flex items-center justify-start cursor-pointer"
                                                onClick={() => DropDown(a)}
                                            >
                                                <img className="h-[20px] ml-3" src={a.img} alt="Settings Logo" />
                                                <p className="text-xl text-left text-[#aaa]  pl-[20px] pr-[10px]">{a.title}</p>
                                                <img className="" src={Aerrow} alt="Arrow" />
                                            </div>
                                            <div className="w-[100%] h-0.5 opacity-50 bg-[#3b3f49]" />
                                            <div>
                                                {a.SubMenu.map(b => (
                                                    <React.Fragment key={b.title}>
                                                        <div className="h-[44px] md:h-[64px] lg:h-[78px] flex items-center justify-start" onClick={() => peration(b)}>
                                                            <p className="text-xl text-left text-[#aaa] pl-[20px] pr-[10px] cursor-pointer">{b.title}</p>
                                                            {b.Event && (
                                                                <div className="w-[30%] md:w-[35%] lg:w-[40%] flex justify-end">
                                                                    <label className="relative inline-flex items-center cursor-pointer">
                                                                        <input type="checkbox" value="true" className="sr-only peer" checked={Not} onClick={() => toggleCheckbox()} />
                                                                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                                    </label>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="w-full h-0.5 opacity-50 bg-[#3b3f49]" />
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            {/* Delete Confirmation Modal */}
            {showDeleteConfirmation && (
                <div className='fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75'>
                    <div className='bg-[#3B3F49] p-6 rounded-md shadow-md text-center text-[white]'>
                        <h3 className='text-lg font-bold mb-4'>Are you sure you want to delete your account?</h3>
                        <div className='flex justify-around'>
                            <button className='bg-[#ffba40] text-white px-4 py-2 rounded-md' onClick={confirmDelete}>Yes</button>
                            <button className='bg-gray-300 text-black px-4 py-2 rounded-md' onClick={() => setShowDeleteConfirmation(false)}>No</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Profile;
