import React, { useEffect, useState, useRef } from 'react';
import Eye_Icon from '../../../assets/Eye_Icon.svg';
import ClearEye from '../../../assets/clearEye.svg';
import RightAerrowth from '../../../assets/LeftAerrow.svg';
import NumberSuportComponent from './NumberSuportComponent'
import Rinput from '../../Helper/RInput'
import { Setcheckuserstatus } from '../../../server/index'
import './LoginStyle.css';
import { useDispatch } from 'react-redux';
import { StoreLanguage } from '../../Store/index';
import { SetUserOtp } from '../../../server/index'
// import { ErrorMessage } from '../../../views/Configurations/language'
const LoginWithNumber = (prop) => {
    const [UserRefData, SetUserRefData] = useState(prop?.UserinputData)
    const [userstatus, setuserstatus] = useState(false)
    const [Otp, setOpt] = useState('')
    const dispatch = useDispatch()
    const [UserData, setUserData] = useState({
        country: '',
        phone_number: '',
        password: '',
        country_code: '',
        otp: '',
        confirm_password: '',
        userstatus: ''
    });
    const [timer, setTimer] = useState(60);

    useEffect(() => {
        let interval;

        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [timer]);

    const handleResend = async () => {
        if (timer === 0) {
            try {
                let { data } = await SetUserOtp(prop.UserinputData)
            } catch (error) {

            }
            setTimer(60);
        }
    };
    const Rinputdata = (Data) => {
        if (prop.data.tag === 'Login_Info') {
            if (prop.data.content.PassHolder === Data.Name) {
                setUserData((prevState) => ({
                    ...prevState,
                    userstatus: userstatus,
                    password: Data.UserEnterData
                }));
            } else {
                setUserData((prevState) => ({
                    ...prevState,
                    userstatus: userstatus,
                    [Data.Name]: Data.UserEnterData
                }))
            }
        }
    };
    const checkuser = async (propdata) => {
        try {
            const { data } = await Setcheckuserstatus(propdata)
            setuserstatus(data.user_exists)
        } catch (error) {
            if (error?.response?.status === 500) {
                dispatch(StoreLanguage.setProps([{
                    Title: '500 server error',
                    SubTitle: '500 server error',
                    Styling: {
                        Icon: 'Error',
                    }
                }]));
            } else {
                let temp = []
                for (let er in error?.response?.data) {
                    temp.push({
                        Title: er,
                        SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
                        Styling: {
                            Icon: 'Error',
                        }
                    })
                }
                dispatch(StoreLanguage.setProps(temp))
            }
        }

    }
    useEffect(() => {
        if (UserData?.phone_number?.length > 6) {
            checkuser(UserData)
        }
    }, [UserData]);
    const countryPhoneNumber = (data) => {
        setUserData((prevState) => ({
            ...prevState,
            userstatus: userstatus,
            country: data.CountryName,
            phone_number: data.Number,
            country_code: `+${data.phoneCode}`,
            otp: data.otp
        }));
    }
    const findEmptyFields = (obj) => {
        const emptyFields = Object.entries(obj)
            .filter(([key, value]) => value === '')
            .map(([key, value]) => key);

        return emptyFields;
    };
    const alldata = (Propdata) => {
        if (Propdata?.Status === 'ForGetPassword') {
            prop.UserData({ ...UserData, ...Propdata })
            return
        }
        let data = findEmptyFields(UserData)
        if (prop.statusdata === 'LoginWithNumber') {
            prop.UserData({ ...UserData, Status: 'ConformNumber' })
            // prop.UserData({ ...UserData, Status: 'OtpScreen' })
        } else if (prop.statusdata === 'inetialpager') {
            let newData = { ...UserRefData };
            for (let key in UserData) {
                if (UserData.hasOwnProperty(key) && UserData[key] !== '') {
                    newData[key] = UserData[key];
                }
            }
            dispatch(StoreLanguage.setProps(
                [prop.rawdata?.PopUpData.SuccessMessage.ConformNumber]
            ))
            prop.UserData({ ...newData, Status: 'OtpScreen' });
        } else if (prop.statusdata === 'VerifyOtp') {
            let newData = { ...UserRefData };
            dispatch(StoreLanguage.setProps(
                [prop.rawdata?.PopUpData.SuccessMessage.OTPNumber]
            ))
            prop.UserData({ ...newData, otp: Otp, Status: 'VerifyOTP' });
        } else if (prop.statusdata === 'resetPass') {
            let newData = { ...UserData };
            dispatch(StoreLanguage.setProps(
                [prop.rawdata?.PopUpData.SuccessMessage.passwordNumber]
            ))
            prop.UserData({ ...{ ...prop.UserinputData, confirm_password: UserData.confirm_password, password: UserData.password }, Status: 'forgetpassword' });
        } else {
            dispatch(StoreLanguage.setProps(
                [data.length >= 3 ? prop.rawdata?.PopUpData.ErrorMessage['AllDataError'] : prop.rawdata?.PopUpData.ErrorMessage[data[0]]]
            ))
        }
    }
    const OtpData = (prop) => {
        if (prop.length === 4) {
            setOpt(prop)
        } else {
            console.error('Please Eter full OPT')
        }
    }
    function OTPInput() {
        const input1Ref = useRef(null);
        const input2Ref = useRef(null);
        const input3Ref = useRef(null);
        const input4Ref = useRef(null);
        const handleInput = (event, ref, nextRef, prevRef) => {
            if (event.target.value.length === 1 && nextRef) {
                nextRef.current.focus();
            } else if (event.key === 'Backspace' && event.target.value === '' && prevRef) {
                prevRef.current.focus();
            }
            OtpData(`${input1Ref.current.value}${input2Ref.current.value}${input3Ref.current.value}${input4Ref.current.value}`)
        };

        return (
            <>
                <input
                    type="tel"
                    className="outline-none w-[60px] h-[60px] bg-white/[0.15] border border-[#bbb] mr-[30px] text-white Def-font text-xl rounded-[10px] text-center"
                    maxLength={1}
                    placeholder={prop.data.content.ConfirmPass}
                    ref={input1Ref}
                    onInput={(event) => handleInput(event, input1Ref, input2Ref, null)}
                    onKeyDown={(event) => handleInput(event, input1Ref, input2Ref, null)}
                />
                <input
                    type="tel"
                    className="outline-none w-[60px] h-[60px] bg-white/[0.15] border border-[#bbb] mr-[30px] text-white Def-font text-xl rounded-[10px] text-center"
                    maxLength={1}
                    placeholder={prop.data.content.ConfirmPass}
                    ref={input2Ref}
                    onInput={(event) => handleInput(event, input2Ref, input3Ref, input1Ref)}
                    onKeyDown={(event) => handleInput(event, input2Ref, input3Ref, input1Ref)}
                />
                <input
                    type="tel"
                    className="outline-none w-[60px] h-[60px] bg-white/[0.15] border border-[#bbb] mr-[30px] text-white Def-font text-xl rounded-[10px] text-center"
                    maxLength={1}
                    placeholder={prop.data.content.ConfirmPass}
                    ref={input3Ref}
                    onInput={(event) => handleInput(event, input3Ref, input4Ref, input2Ref)}
                    onKeyDown={(event) => handleInput(event, input3Ref, input4Ref, input2Ref)}
                />
                <input
                    type="tel"
                    className="outline-none w-[60px] h-[60px] bg-white/[0.15] border border-[#bbb] mr-[30px] text-white Def-font text-xl rounded-[10px] text-center"
                    maxLength={1}
                    placeholder={prop.data.content.ConfirmPass}
                    ref={input4Ref}
                    onInput={(event) => handleInput(event, input4Ref, null, input3Ref)}
                    onKeyDown={(event) => handleInput(event, input4Ref, null, input3Ref)}
                />
            </>
        );
    }
    return (
        <div className="w-full md:w-[95%] lg:w-[75%] p-[10px] md:p-[20px] lg:p-[40px] h-[100vh] md:h-[70vh] lg:h-[80vh] overflow-scroll">
            <img
                onClick={() => prop.Backtoperiouspage(prop.routeDirection)}
                src={RightAerrowth}
                className="absolute left-[2%] transform translate_y_1 w-8 h-[40px] rounded object-none object_fit cursor-pointer z-8"
                alt="Toggle Password Visibility"
            />
            <div className='mb-[30px] md:mb-[35px] lg:mb-[5%] mt-[15%] md:mt-0 lg:mt-0'>
                <p className="text-[20px] md:text-[25px] lg:text-[30px] text-left mb-[10px] Def-font text-white">
                    {prop.data.Title}
                </p>
                <p className="text-[12px] md-text-[18px] lg:text-[20px] font-medium text-left Def-font text-white">
                    {prop.data.SubTitle} {prop.data.content.Otp && <>
                        {prop.UserinputData.country_code}-
                        {prop.UserinputData.phone_number}
                    </>}
                </p>
            </div>
            <div className='flex Def-font'>
                {
                    prop.data.content.Otp && <div className=''>
                        {
                            OTPInput()
                        }
                        <div className="w-[395px] h-[22px] mt-[20px] flex">
                            <p className="text-md text-left text-[#999] mr-1">
                                {prop.data.footer}
                            </p>
                            <p className="text-sm font-medium text-left text-[#41afff]">
                                <div>
                                    {timer === 0 && <div disabled={timer > 0} className='cursor-pointer' onClick={handleResend}>
                                        Resend
                                    </div>}
                                    {timer > 0 && <p>Resend in {timer} sec</p>}
                                </div>
                            </p>
                        </div>
                    </div>
                }
            </div>
            {UserData.password.length > 4 && <div className='text-sm font-medium text-left text-[#ff4141]'>Please Enter only 4 Digits Pin</div>}
            {(UserData.password !== UserData.confirm_password && prop.data.content.ConfirmPass) && <div className='text-sm font-medium text-left text-[#ff4141]'>Both Password are not same</div>}
            {prop.data.DefaultCountry &&
                <div className="w-full">
                    <NumberSuportComponent
                        data={prop.data}
                        UserData={UserData}
                        countryPhoneNumber={countryPhoneNumber}
                        userInputData={prop.UserinputData}
                    />
                </div>
            }
            {(prop.data.content.Password && (prop.statusdata === 'LoginWithNumber' ? userstatus : true)) &&
                <Rinput data={{ Holder: prop.data.content.PassHolder, Icon: Eye_Icon, Clear: ClearEye, tags: 'password' }} Rinputdata={Rinputdata} />
            }
            {prop.data.content.ConfirmPass &&
                <Rinput data={{ Holder: prop.data.content.ConfirmPass, Icon: Eye_Icon, Clear: ClearEye, tags: 'confirm_password' }} Rinputdata={Rinputdata} />
            }
            {!prop.data.content.Password &&
                <div className="relative h-[10px] md:h-[80px] lg:h-[100px] pt-[30px] rounded-b-[10px]">
                </div>
            }

            {(prop.data.ForgetPass && userstatus) &&
                <div className='text-lg Def-font font-medium text-right text-[#41afff] cursor-pointer' onClick={() => alldata({ Status: 'ForGetPassword' })}>{prop.data.ForgetPass}</div>
            }
            {prop.data.content.ConfirmPass ?
                (<>
                    {((UserData.password.length === 4 || UserData.confirm_password.length === 4) && UserData.password === UserData.confirm_password) ?
                        (<div className="w-full my-[6%] h-[58px] rounded-[10px] Login_Best cursor-pointer" onClick={() => alldata()}>
                            <p className="text-xl Def-font font-bold text-center uppercase text-[#333]">
                                {prop.data.Button}
                            </p>
                        </div>) : (<div className="w-full mt-[3%] md:mt-[5%]  lg:mt-[6%] h-[58px] rounded-[10px] bg-[#a69f9f] flex justify-center items-center cursor-pointer">
                            <p className="text-xl Def-font font-bold text-center uppercase text-[#333]">
                                {prop.data.Button}
                            </p>
                        </div>
                        )
                    }
                </>) : (
                    <div className="w-full my-[6%] h-[58px] rounded-[10px] Login_Best cursor-pointer" onClick={() => alldata()}>
                        <p className="text-xl Def-font font-bold text-center uppercase text-[#333]">
                            {prop.data.Button}
                        </p>
                    </div>
                )
            }
        </div>
    );
};

export default LoginWithNumber;
