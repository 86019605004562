import logo from '../../assets/logo.svg';
import Service24x7 from '../../assets/Service24x7.svg';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetbackRequest, get_in_touch } from '../../server/index';
import { SetProfileStatus, StoreLanguage } from '../Store/index';
import _ from 'lodash';

function FooterNavBar(props) {
    const dispatch = useDispatch();
    const profiles = useSelector(state => state.storeContent.UserData);

    const TriggerProfile = (prop) => {
        console.log(prop, 'prop');
        if (prop === "contact") {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            ClickOnCall();
        } else {
            dispatch(SetProfileStatus());
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const [popUpData, setpopUpData] = useState(false);
    const [formData, setFormData] = useState({ email: '', description: '' });

    const sendRequest = async () => {
        try {
            await get_in_touch(profiles?.Authorization && {
                headers: {
                    Authorization: `Token ${profiles?.Authorization}`,
                }
            }, {
                ...formData
            });
            setpopUpData(false)
            setFormData({ email: '', description: '' })
            dispatch(StoreLanguage.setProps([{
                Title: 'Request Submitted Successfully',
                SubTitle: 'Thank you! Our team will contact you shortly.',
                Styling: {
                    Icon: 'Success',
                }
            }]));
        } catch (error) {
            if (error?.response?.status === 500) {
                dispatch(StoreLanguage.setProps([{
                    Title: '500 server error',
                    SubTitle: '500 server error',
                    Styling: {
                        Icon: 'Error',
                    }
                }]));
            } else {
                let temp = [];
                for (let er in error?.response?.data) {
                    temp.push({
                        Title: er,
                        SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
                        Styling: {
                            Icon: 'Error',
                        }
                    });
                }
                dispatch(StoreLanguage.setProps(temp));
            }
        }
    };

    const ClickOnCall = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setpopUpData(true);
    };


    const close = () => {
        setpopUpData(false)
        setFormData({ email: '', description: '' })
    }
    const send = () => {
        sendRequest()
    }

    const handleChange = (event) => {
        let { name, value } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const chunks = _.chunk(props.data.footer, 3);

    return (
        <>
            {popUpData && (
                <div className='w-full min-h-full h-auto z-[12] bg_blur fixed top-0'>
                    <div className='w-full h-[100vh] flex justify-center items-center'>
                        <div className=' bg-[#3B3F49] w-full md:w-[50%] lg:w-[25%] rounded-lg'>
                            <div className='p-4'>
                                <div className='w-full flex justify-end'>
                                    <div className="w-[30px] h-[30px] rounded-full text-xl cursor-pointer text-white bg-[#62656D]" onClick={() => close()}>
                                        <div className="flex items-center justify-center">x</div>
                                    </div>
                                </div>
                                <div className='px-4 pb-[20px]'>
                                    <div>
                                        <div className='flex items-start flex-col text-white py-5'>
                                            <div className='text-lg pb-1 flex flex-col gap-3 w-full'>
                                                <div className='flex items-start flex-col'>
                                                    <div>Email</div>
                                                    <input
                                                        type="email"
                                                        className="outline-none w-full bg-white/[0.15] border border-[#bbb] pl-[20px] pr-[20px] h-[50px] text-white Def-font text-lg rounded-[10px]"
                                                        placeholder="Email"
                                                        value={formData.email}
                                                        name='email'
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className='flex items-start flex-col'>
                                                    <div>Description</div>
                                                    <textarea
                                                        className="outline-none w-full bg-white/[0.15] border border-[#bbb] pl-[20px] pr-[20px] h-[150px] text-white Def-font text-lg rounded-[10px]"
                                                        placeholder="Description"
                                                        rows="4"
                                                        value={formData.description}
                                                        name='description'
                                                        onChange={handleChange}
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`w-full flex items-center pb-2 justify-around`}>
                                    <div className="w-[90px] h-8 rounded-[5px] cursor-pointer flex justify-center items-center" onClick={() => close()} style={{ background: "linear-gradient(220.6deg, #ffbe41 60.41%, #ff9334 121.42%)" }}>
                                        <p className="text-md font-bold text-center uppercase text-[rgb(51,51,51)] center_aline">
                                            Close
                                        </p>
                                    </div>
                                    <div className="w-[90px] h-8 rounded-[5px] cursor-pointer flex justify-center items-center" onClick={() => send()} style={{ background: "linear-gradient(220.6deg, #ffbe41 60.41%, #ff9334 121.42%)" }}>
                                        <p className="text-md font-bold text-center uppercase text-[rgb(51,51,51)] center_aline">
                                            Send
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <footer className="bg-[#2D3136]">
                <div className="mx-auto w-full py-6 lg:py-8">
                    <div className="md:flex md:justify-between lg:h-[165px] mx-[5%]">
                        <div className="mb-6 md:mb-0 lg:w-[25%]">
                            <a href="#" className="flex items-center">
                                <img src={logo} className="w-12 h-12 mr-7" alt="logo" />
                                <span className="self-center Def-font text-white text-2xl font-semibold whitespace-nowrap">
                                    {props.data.Footer_LogoTitle}
                                </span>
                            </a>
                        </div>
                        <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3 lg:w-1/2">
                            {chunks.map((arr, index) => (
                                <div key={index}>
                                    {arr.map((user, i) => (
                                        <div key={i}>
                                            <ul className="text-white Def-font">
                                                <li className="mb-4" key={user.title}>
                                                    {user.RedirectUrl ? (
                                                        <Link to={user.RedirectUrl} className="hover:underline" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                                                            {user.title}
                                                        </Link>
                                                    ) : (
                                                        <div onClick={() => TriggerProfile(user.other)} className="hover:underline cursor-pointer Def-font">
                                                            {user.title}
                                                        </div>
                                                    )}
                                                </li>
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                        <div className='lg:w-[25%] hidden lg:block md:block'>
                            <div className="lg:w-[100%] flex justify-end">
                                <img src={Service24x7} className="w-30 h-30" alt="logo" />
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-center">
                        <span className="text-sm sm:text-center Def-font text-gray-500">
                            2022 © <a href="https://flowbite.com/" className="hover:underline">Jaarvis™</a>. All Rights Reserved.
                        </span>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default FooterNavBar;
