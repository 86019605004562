import logo from '../../assets/logo.svg';
import location from '../../assets/location.svg';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import './HeaderNavBarStyle.css'
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setLanguage, SetUserData, SetProfileStatus, SetMap, SetMOT, setProps, setsearch } from '../Store/index'
import { countries } from 'country-data';
function HeaderNavBar(prop) {
    const searchResult = useSelector(state => state.storeContent.search);
    const history = useNavigate();
    const currency_obj = {
        "USD": { "name": "United States Dollar", "symbol": "$", "format": "en-US", "key": "USD", "timezone": "GMT-5:00" },
        "EUR": { "name": "Euro", "symbol": "€", "format": "en-US", "key": "EUR", "timezone": "GMT+1:00" },
        "JPY": { "name": "Japanese Yen", "symbol": "¥", "format": "ja-JP", "key": "JPY", "timezone": "GMT+9:00" },
        "GBP": { "name": "British Pound Sterling", "symbol": "£", "format": "en-GB", "key": "GBP", "timezone": "GMT+0:00" },
        "CAD": { "name": "Canadian Dollar", "symbol": "C$", "format": "en-CA", "key": "CAD", "timezone": "GMT-5:00" },
        "AUD": { "name": "Australian Dollar", "symbol": "A$", "format": "en-AU", "key": "AUD", "timezone": "GMT+10:00" },
        "CHF": { "name": "Swiss Franc", "symbol": "CHF", "format": "de-CH", "key": "CHF", "timezone": "GMT+1:00" },
        "CNY": { "name": "Chinese Yuan", "symbol": "¥", "format": "zh-CN", "key": "CNY", "timezone": "GMT+8:00" },
        "INR": { "name": "Indian Rupee", "symbol": "₹", "format": "en-IN", "key": "INR", "timezone": "GMT+5:30" },
        "SGD": { "name": "Singapore Dollar", "symbol": "S$", "format": "en-SG", "key": "SGD", "timezone": "GMT+8:00" },
        "NZD": { "name": "New Zealand Dollar", "symbol": "NZ$", "format": "en-NZ", "key": "NZD", "timezone": "GMT+12:00" },
        "BRL": { "name": "Brazilian Real", "symbol": "R$", "format": "pt-BR", "key": "BRL", "timezone": "GMT-3:00" },
        "RUB": { "name": "Russian Ruble", "symbol": "₽", "format": "ru-RU", "key": "RUB", "timezone": "GMT+3:00" },
        "ZAR": { "name": "South African Rand", "symbol": "R", "format": "en-ZA", "key": "ZAR", "timezone": "GMT+2:00" },
        "AED": { "name": "United Arab Emirates Dirham", "symbol": "د.إ", "format": "ar-AE", "key": "AED", "timezone": "GMT+4:00" },
        "MXN": { "name": "Mexican Peso", "symbol": "Mex$", "format": "es-MX", "key": "MXN", "timezone": "GMT-6:00" },
        "HKD": { "name": "Hong Kong Dollar", "symbol": "HK$", "format": "en-HK", "key": "HKD", "timezone": "GMT+8:00" },
        "SEK": { "name": "Swedish Krona", "symbol": "kr", "format": "sv-SE", "key": "SEK", "timezone": "GMT+1:00" },
        "NOK": { "name": "Norwegian Krone", "symbol": "kr", "format": "nb-NO", "key": "NOK", "timezone": "GMT+1:00" },
        "DKK": { "name": "Danish Krone", "symbol": "kr", "format": "da-DK", "key": "DKK", "timezone": "GMT+1:00" },
        "TRY": { "name": "Turkish Lira", "symbol": "₺", "format": "tr-TR", "key": "TRY", "timezone": "GMT+3:00" },
        "PLN": { "name": "Polish Złoty", "symbol": "zł", "format": "pl-PL", "key": "PLN", "timezone": "GMT+1:00" },
        "THB": { "name": "Thai Baht", "symbol": "฿", "format": "th-TH", "key": "THB", "timezone": "GMT+7:00" },
        "IDR": { "name": "Indonesian Rupiah", "symbol": "Rp", "format": "id-ID", "key": "IDR", "timezone": "GMT+7:00" },
        "MYR": { "name": "Malaysian Ringgit", "symbol": "RM", "format": "ms-MY", "key": "MYR", "timezone": "GMT+8:00" },
        "PHP": { "name": "Philippine Peso", "symbol": "₱", "format": "en-PH", "key": "PHP", "timezone": "GMT+8:00" },
        "COP": { "name": "Colombian Peso", "symbol": "Col$", "format": "es-CO", "key": "COP", "timezone": "GMT-5:00" },
        "ARS": { "name": "Argentine Peso", "symbol": "ARS$", "format": "es-AR", "key": "ARS", "timezone": "GMT-3:00" },
        "EGP": { "name": "Egyptian Pound", "symbol": "EGP", "format": "ar-EG", "key": "EGP", "timezone": "GMT+2:00" },
        "ILS": { "name": "Israeli New Shekel", "symbol": "₪", "format": "he-IL", "key": "ILS", "timezone": "GMT+2:00" },
        "KRW": { "name": "South Korean Won", "symbol": "₩", "format": "ko-KR", "key": "KRW", "timezone": "GMT+9:00" },
        "TWD": { "name": "New Taiwan Dollar", "symbol": "NT$", "format": "zh-TW", "key": "TWD", "timezone": "GMT+8:00" },
        "VND": { "name": "Vietnamese Dong", "symbol": "₫", "format": "vi-VN", "key": "VND", "timezone": "GMT+7:00" },
        "NGN": { "name": "Nigerian Naira", "symbol": "₦", "format": "en-NG", "key": "NGN", "timezone": "GMT+1:00" },
        "CLP": { "name": "Chilean Peso", "symbol": "CLP$", "format": "es-CL", "key": "CLP", "timezone": "GMT-3:00" },
        "CZK": { "name": "Czech Koruna", "symbol": "Kč", "format": "cs-CZ", "key": "CZK", "timezone": "GMT+1:00" },
        "PKR": { "name": "Pakistani Rupee", "symbol": "₨", "format": "ur-PK", "key": "PKR", "timezone": "GMT+5:00" },
        "BGN": { "name": "Bulgarian Lev", "symbol": "лв", "format": "bg-BG", "key": "BGN", "timezone": "GMT+2:00" },
        "HRK": { "name": "Croatian Kuna", "symbol": "kn", "format": "hr-HR", "key": "HRK", "timezone": "GMT+1:00" },
        "RON": { "name": "Romanian Leu", "symbol": "lei", "format": "ro-RO", "key": "RON", "timezone": "GMT+2:00" },
        "HUF": { "name": "Hungarian Forint", "symbol": "Ft", "format": "hu-HU", "key": "HUF", "timezone": "GMT+1:00" },
        "ISK": { "name": "Icelandic Króna", "symbol": "kr", "format": "is-IS", "key": "ISK", "timezone": "GMT+0:00" },
        "NPR": { "name": "Nepalese Rupee", "symbol": "₨", "format": "ne-NP", "key": "NPR", "timezone": "GMT+5:45" },
        "LKR": { "name": "Sri Lankan Rupee", "symbol": "₨", "format": "si-LK", "key": "LKR", "timezone": "GMT+5:30" },
        "DZD": { "name": "Algerian Dinar", "symbol": "دج", "format": "ar-DZ", "key": "DZD", "timezone": "GMT+1:00" },
        "IRR": { "name": "Iranian Rial", "symbol": "﷼", "format": "fa-IR", "key": "IRR", "timezone": "GMT+3:30" },
        "GHS": { "name": "Ghanaian Cedi", "symbol": "GH₵", "format": "en-GH", "key": "GHS", "timezone": "GMT+0:00" },
        "HNL": { "name": "Honduran Lempira", "symbol": "L", "format": "es-HN", "key": "HNL", "timezone": "GMT-6:00" },
        "CDF": { "name": "Congolese Franc", "symbol": "FC", "format": "fr-CD", "key": "CDF", "timezone": "GMT+1:00" },
        "UGX": { "name": "Ugandan Shilling", "symbol": "USh", "format": "en-UG", "key": "UGX", "timezone": "GMT+3:00" },
        "BMD": { "name": "Bermudian Dollar", "symbol": "BD$", "format": "en-BM", "key": "BMD", "timezone": "GMT-4:00" },
        "AMD": { "name": "Armenian Dram", "symbol": "֏", "format": "hy-AM", "key": "AMD", "timezone": "GMT+4:00" },
        "NAD": { "name": "Namibian Dollar", "symbol": "N$", "format": "en-NA", "key": "NAD", "timezone": "GMT+2:00" },
        "AON": { "name": "Angolan New Kwanza", "symbol": "AON", "format": "pt-AO", "key": "AON", "timezone": "GMT+1:00" },
        "BAM": { "name": "Bosnia-Herzegovina Convertible Mark", "symbol": "KM", "format": "bs-Latn-BA", "key": "BAM", "timezone": "GMT+1:00" },
        "LSL": { "name": "Lesotho Loti", "symbol": "L", "format": "en-LS", "key": "LSL", "timezone": "GMT+2:00" },
        "MMK": { "name": "Burmese Kyat", "symbol": "K", "format": "my-MM", "key": "MMK", "timezone": "GMT+6:30" },
        "PGK": { "name": "Papua New Guinean Kina", "symbol": "K", "format": "en-PG", "key": "PGK", "timezone": "GMT+10:00" },
        "PYG": { "name": "Paraguayan Guarani", "symbol": "₲", "format": "es-PY", "key": "PYG", "timezone": "GMT-4:00" },
        "SLL": { "name": "Sierra Leonean Leone", "symbol": "Le", "format": "en-SL", "key": "SLL", "timezone": "GMT+0:00" },
        "TMT": { "name": "Turkmenistani Manat", "symbol": "T", "format": "tk-TM", "key": "TMT", "timezone": "GMT+5:00" },
        "UYU": { "name": "Uruguayan Peso", "symbol": "$U", "format": "es-UY", "key": "UYU", "timezone": "GMT-3:00" },
        "VES": { "name": "Venezuelan Bolívar Soberano", "symbol": "Bs.", "format": "es-VE", "key": "VES", "timezone": "GMT-4:00" },
        "YER": { "name": "Yemeni Rial", "symbol": "﷼", "format": "ar-YE", "key": "YER", "timezone": "GMT+5:30" },
    };
    const findCurrencyByCountryName = (countryName) => {
        const country = countries.all.find(
            (c) => c.name.toLowerCase() === countryName.toLowerCase()
        );
        return country ? country.currencies[0] : null;
    };
    useEffect(() => {
        if (searchResult) {
            history('/search');
        }
    }, [searchResult]);
    const dispatch = useDispatch()
    const [HideMenu, SetMenuStatus] = useState(true)
    const [CarsData, SetcarsData] = useState(prop)
    const [Profile, setProfile] = useState();
    const [tempSearch, setTempSearch] = useState([])
    const [showDropDown, setshowDropDown] = useState(false);
    const profiles = useSelector(state => state.storeContent.UserData);
    const storedValue = localStorage.getItem(`Language`) ? localStorage.getItem(`Language`) : 'eng';
    let UserLanguage = useSelector(state => state.storeContent.StoreLanguage);
    const ShowDropDown = (propData, moreinfo) => {
        if (propData.icon) {
            setshowDropDown(!showDropDown)
        }
        if (moreinfo?.click === "Mot") {
            if (profiles?.Authorization) {
                dispatch(SetMOT(true));
            } else {
                dispatch(setProps([prop.language.PopUpData.ErrorMessage.NoticeUserToLogin]))
            }
        } else if (moreinfo?.click === "Road") {
            if (profiles?.Authorization) {
                dispatch(SetMap(true));
            } else {
                dispatch(setProps([prop.language.PopUpData.ErrorMessage.NoticeUserToLogin]))
            }
        } else if (moreinfo?.click === "Mot History") {
            if (profiles?.Authorization) {
                history('/mot_history');
            } else {
                dispatch(setProps([prop.language.PopUpData.ErrorMessage.NoticeUserToLogin]))
            }
        } else if (!profiles?.Authorization && moreinfo?.checkUserLogin) {
            dispatch(setProps([prop.language.PopUpData.ErrorMessage.NoticeUserToLogin]))
        }
    }
    const profileAction = () => {
        dispatch(SetProfileStatus());
    }
    useEffect(() => {
        dispatch(setLanguage(storedValue))
    }, [UserLanguage]);
    useEffect(() => {
        if (prop.data.country) {
            let currencyCode = findCurrencyByCountryName(prop.data.country ? prop.data.country : 'United States');
            localStorage.setItem(`Cur`, JSON.stringify({ ...currency_obj[[currencyCode]] }));
        }
    }, [prop])
    useEffect(() => {
        let currencyCode = findCurrencyByCountryName(prop.data.country ? prop.data.country : 'United States');
        localStorage.setItem(`Cur`, JSON.stringify({ ...currency_obj[[currencyCode]] }));
    }, [])
    useEffect(() => {
        if (profiles && Object.keys(profiles).length) {
            localStorage.setItem(`UserData`, JSON.stringify(profiles));
        } else {
            const storedProfile = JSON.parse(localStorage.getItem(`UserData`));
            setProfile(storedProfile)
            dispatch(SetUserData(storedProfile))
        }
    }, [profiles]);
    const LanguageKey = {
        chi: '中',
        eng: '英'
    }
    // clear or keep (it will close when we clcik on same drop down or any where else)
    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setshowDropDown(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const findSelectedLan = (Props) => {
        localStorage.setItem(`Language`, Props)
        dispatch(setLanguage(Props))
    }
    const findKeyByValue = (value) => {
        let convertdata = ''
        if (/^[A-Za-z]+$/.test(value)) {
            convertdata = value.toLowerCase()
        } else {
            convertdata = value
        }
        for (const key in LanguageKey) {
            if (LanguageKey[key] === convertdata) {
                return key;
            }
        }
        for (const key in LanguageKey) {
            if (key === convertdata) {
                return convertdata;
            }
        }
    };
    useEffect(() => {
        SetcarsData(prop)
    }, [prop]);
    const checker = (props) => {
        prop.UnableSignin(profiles?.Authorization)
    }
    const Toggle = () => {
        SetMenuStatus(!HideMenu)
    }
    const searchData = (event) => {
        setTempSearch(event.target.value)
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            dispatch(setsearch(tempSearch))
        }
    };
    const SelectedLan = useSelector(state => state.storeContent.language);
    const MenuSet = () => {
        return (<div className="w-full flex-grow flex flex-col justify-center items-center md:flex-grow md:justify-end md:flex-row lg:flex-row lg:flex lg:items-center lg:w-auto h-full Def-font">
            <div className="text-sm Def-font Def-font flex flex-col justify-center items-center md:flex-grow md:justify-end md:flex-row lg:flex-row lg:flex-grow lg:justify-end h-full">
                {CarsData.language.NavMenu.map((user, index) => (
                    <div className="h-full flex items-center" key={index}>
                        {!user.RedirectUrl ? (
                            <div className="h-full flex items-center">
                                {user.title.map((Nlan, index) =>
                                    <div className="h-full items-center" key={index}>
                                        {user.title.length === index + 1 ? (
                                            <div
                                                className={`block mt-4 lg:inline-block lg:mt-0 text-base font-medium text-left Def-font  hover:text-[#FFBA40] mr-4 cursor-pointer ${SelectedLan == findKeyByValue(Nlan)
                                                    ? 'text-[#FFBA40] h-full flex items-center'
                                                    : 'text-white h-full flex items-center'
                                                    }`}
                                                style={{ display: 'flex' }}
                                                onClick={() => findSelectedLan(findKeyByValue(Nlan))}
                                                key={Nlan}
                                            >
                                                {Nlan}
                                            </div>
                                        ) : (
                                            <div className="flex items-center h-full" key={index}>
                                                <div
                                                    className={`block mt-4 lg:inline-block lg:mt-0 cursor-pointer Def-font text-base font-medium text-left hover:text-[#FFBA40] mr-4 ${SelectedLan == findKeyByValue(Nlan)
                                                        ? 'text-[#FFBA40] h-full flex items-center'
                                                        : 'text-white h-full flex items-center'
                                                        }`}
                                                    style={{ display: 'flex' }}
                                                    onClick={() => findSelectedLan(findKeyByValue(Nlan))}
                                                    key={Nlan}
                                                >
                                                    {Nlan}
                                                </div>
                                                <span className="block mt-4 lg:inline-block lg:mt-0 Def-font Def-font text-base font-medium text-left text-white mr-4">|</span>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        ) : (
                            user.icon ? (
                                <>
                                    <div className="block mt-4 lg:inline-block lg:mt-0 Def-font text-base font-medium text-left text-white hover:text-[#FFBA40] mr-4 h-full relative" key={user}>
                                        {!user.icon && <>
                                            <NavLink
                                                to={user.RedirectUrl ? user.RedirectUrl : '/404'}
                                                className={({ isActive }) => (isActive ? 'border-b-2 border-white h-full Def-font flex items-center' : 'disabled h-full Def-font flex items-center')}
                                            >
                                                {user.title}
                                            </NavLink>
                                        </>}
                                        {user.icon && <>
                                            <NavLink
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    ShowDropDown(user);
                                                }}
                                                className={({ isActive }) => (showDropDown ? 'border-b-2 border-white h-full Def-font flex items-center' : 'disabled h-full Def-font flex items-center')}
                                            >
                                                {user.title}
                                                {user.icon && <div className='pl-[10px]'><img src={user.LeftLogo} className="w-13 h-13" alt="logo" /></div>}
                                            </NavLink>
                                            {showDropDown && (
                                                <div className='absolute' ref={dropdownRef}>
                                                    <div className="w-[275px]">
                                                        <div className="w-[275px] rounded-[20px] bg-[#dce3e7]" style={{ boxShadow: "0px 4px 20px 0 rgba(0,0,0,0.4)" }}>
                                                            {user.dropDowndata.map((c, k) => (
                                                                (!c?.click) ? (<NavLink
                                                                    to={c.RedirectUrl ? c.RedirectUrl : '/404'}
                                                                    onClick={() => {
                                                                        ShowDropDown(user, c);
                                                                    }}
                                                                >
                                                                    <div className='px-[10%]' key={c.title}>
                                                                        <p className={`text-sm font-medium text-left text-[#2d3136] h-[45px] ${k < user.dropDowndata.length - 1 ? 'border-b-2 border-[#AFB5BB]' : ''} flex justify-center items-center`}>{c.title}</p>
                                                                    </div>
                                                                </NavLink>) : (
                                                                    <div
                                                                        className='cursor-pointer'
                                                                        onClick={() => {
                                                                            ShowDropDown(user, c);
                                                                        }}
                                                                    >
                                                                        <div className='px-[10%]' key={c.title}>
                                                                            <p className={`text-sm font-medium text-left text-[#2d3136] h-[45px] ${k < user.dropDowndata.length - 1 ? 'border-b-2 border-[#AFB5BB]' : ''} flex justify-center items-center`}>{c.title}</p>
                                                                        </div>
                                                                    </div>
                                                                )

                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </>}
                                    </div>
                                </>
                            ) : (
                                <div className="block mt-4 lg:inline-block lg:mt-0 Def-font text-base font-medium text-left text-white hover:text-[#FFBA40] mr-4 h-full" key={user}>
                                    <NavLink
                                        to={user.RedirectUrl ? user.RedirectUrl : '/404'}
                                        className={({ isActive }) => (isActive ? 'border-b-2 border-white h-full Def-font flex items-center' : 'disabled h-full Def-font flex items-center')}
                                    >
                                        {user.title}
                                        {user.icon && <div className='pl-[10px]'><img src={user.LeftLogo} className="w-13 h-13" alt="logo" /></div>}
                                    </NavLink>
                                </div>
                            )
                        )}
                    </div>
                ))}
            </div>

            <div className='mr-4'>
                <form className="flex items-center">
                    <label htmlFor="simple-search" className="sr-only">Search</label>
                    <div className="relative w-full">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                        </div>
                        <input type="text" id="simple-search" className="bg-[#4D5763] border-gray-300 Def-font text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-white" placeholder={CarsData.language.HomePageSearch} onChange={searchData} onKeyPress={handleKeyPress} />
                    </div>
                </form>
            </div>
            <div className='flex items-center pt-4 md:pt-0 lg:pt-0'>
                {(!profiles?.phone_number_verified) && <div className="font-medium inline-block text-sm px-4 py-3 leading-none rounded Def-font text-black border-white hover:border-transparent mt-0 lg:mt-0 Sign_in cursor-pointer" onClick={() => checker()}>{profiles?.user_password_updated ? CarsData.language.HomePageSignOut : CarsData.language.HomePageSignIn}</div>}
                {(profiles?.phone_number && (profiles?.user_password_updated || profiles?.phone_number_verified)) && <div className='block'>
                    <div className="w-[50px] h-[50px] rounded-full border-[2.38px] border-[#ffba40]/[0.27] center_aline" onClick={() => profileAction()}>
                        <div className="rounded-full w-full h-full cursor-pointer">
                            {profiles?.profile_image ? (<img src={profiles?.profile_image} className="rounded-full w-full h-full" />) : (<div className='bg-[#FFBA40] w-full h-full rounded-full text-white flex justify-center items-center text-[30px] cursor-pointer'>{profiles.first_name ? profiles.first_name.split('')[0] : 'G'}</div>)}
                        </div>
                    </div>
                </div>}
            </div>
        </div>)
    }
    return (
        <div className='flex justify-center bg-[#2D3136] fixed top-0 left-0 w-full z-[11]'>
            <nav className="flex items-center justify-between flex-wrap h-[50px] md:h-[70px] lg:h-[90px] Header_Box_shadow w-[90%] ">
                <div className="flex items-center flex-shrink-0 text-white w-[80%] md:w-[40%] lg:w-[30%]" >
                    <div className='w-[60px] mr-0 md:mr-3 lg:mr-5'>
                        <NavLink to={'/'}>
                            <img src={logo} className="w-13 h-13" alt="logo" />
                        </NavLink>
                    </div>

                    <span className="font-semibold text-xl tracking-tight flex items-center w-[80%]">
                        <img src={location} className="w-5 h-5" alt="Location" />
                        <p className="text-base font-medium text-left Def-font text-white overflow-hidden whitespace-nowrap flex">
                            <span className="inline-block max-w-full overflow-hidden overflow-ellipsis flex items-center">
                                <span className="truncate xl:inline-block pl-1 md:pl-3 lg:pl-4">
                                    {prop.data.address.replace(new RegExp(', undefined', 'g'), '')}
                                </span>
                            </span>
                        </p>

                    </span>
                </div>
                <div className="block lg:hidden">
                    <button onClick={Toggle} className="flex cursor-pointer items-center px-3 py-2 border rounded Def-font text-teal-200 border-teal-400 hover:text-white hover:border-white">
                        <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <title>Menu</title>
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                        </svg>
                    </button>
                </div>
                <div className="block lg:hidden w-full items-center justify-center z-8 bg-slate-500">
                    {HideMenu || MenuSet()}
                </div>
                <div className='hidden lg:block h-full w-[70%]'>
                    <div className='h-full flex items-center'>
                        {MenuSet()}
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default HeaderNavBar;