// import HomePageBanner from '../../components/Banner/HomePageBanner'
import DownAerrow from '../../assets/Down_Aerrow.svg'
import fb from '../../assets/FB_log.svg'
import google from '../../assets/Google_log.svg'
import mbile from '../../assets/Mobile_log.svg'
import ProfileSvg from '../../assets/Profile.svg'
import MyPost from '../../assets/MyPost.svg'
import BookMark from '../../assets/BookMark.svg'
import mobileBookmark from '../../assets/MobileBookMark.svg'
import History from '../../assets/History.svg'
import Logo from '../../assets/logo.svg'
import LeftAerrow from '../../assets/LeftAerrow.svg'
import Menu from '../../assets/Menu.svg'
const cars = [
  'https://imageio.forbes.com/specials-images/imageserve/5d35eacaf1176b0008974b54/2020-Chevrolet-Corvette-Stingray/0x0.jpg?format=jpg&crop=4560,2565,x790,y784,safe&width=960',
  'https://www.lamborghini.com/sites/it-en/files/DAM/lamborghini/facelift_2019/homepage/families-gallery/2023/revuelto/revuelto_m.png',
  'https://hips.hearstapps.com/hmg-prod/images/nissanz2023-1673297639.jpeg',
  'https://i.ytimg.com/vi/tArC9-RHmU4/maxresdefault.jpg'
]
export const ErrorMessage = {
  Title: "Oops! Our server is sipping coffee ☕️.",
  SubTitle: "We'll fix it soon! Tell us your favorite programming language – maybe it needs some coding inspiration! 😄",
  Styling: {
    Icon: "Warning",
  },
};
export const LoginMessage = {
  Title: "Oops! Please login.",
  SubTitle: "Beforce processing for there steps!, Please Login",
  Styling: {
    Icon: "Warning",
  },
};
const lastBaner = {
  title: 'Get in touch',
  subtitle: 'Request a test drive or a quote, and get in touch with signing-in',
  button: 'Get in touch',
  GetinTouch: {
    Title: 'Team Will Contact',
    SubTitle: 'Team will contact user via email or phone promptly.',
    Styling: {
      Icon: 'Success',
    }
  },
}
const lastBannerCHI = {
  title: '保持联系',
  subtitle: '请求试驾或报价，并通过登录与我们保持联系',
  button: '保持联系',
  GetinTouch: {
    Title: 'Team Will Contact',
    SubTitle: 'Team will contact user via email or phone promptly.',
    Styling: {
      Icon: 'Success',
    }
  },
}
const language =
{
  eng: {
    welcomeMsg: 'Sharing smile through servicing',
    InternetIssuesMsg: `Everything's fine, the internet is thriving!`,
    Loading: 'Loading...',
    ServerError: '404 Page Not Found',
    HomePageSignIn: 'Sign In',
    HomePageSignOut: 'Sign Out',
    HomePageSearch: 'Search',
    defaultName: 'Customer Name',
    productCards: {
      SortBy: 'Sort By',
      clearFilter: 'Clear Filter',
      sell: {
        title: 'Sell',
        tag: 'Sell'
      },
      buy: {
        title: 'Buy',
        tag: 'Buy'
      },
    },
    productdata: {
      WriteReview: 'Write Review',
      CallMeet: 'Call to Meet',
      Button: 'ADD TO COMPARE',
      Status: 'Available in stock',
      userReview: 'User Reviews'
    },
    productTab: [
      {
        mainTitle: 'Overview',
        Title: 'Overview',
        Unable: true,
      }, {
        mainTitle: 'Features',
        Title: 'Features',
        Unable: false
      }, {
        mainTitle: 'Specification',
        Title: 'Specification',
        Unable: false
      }
    ],
    inetialPage: [
      {
        title: 'Owner',
        tag: 'owner'
      },
      {
        title: 'Year',
        tag: 'year'
      },
      // {
      //   title: 'History',
      //   tag: 'history'
      // },
      {
        title: 'Kilometers Driven',
        tag: 'km_driven'
      },
      // {
      //   title: 'Registered In',
      //   tag: 'registered_in'
      // },
      {
        title: 'Engine Displacement',
        tag: 'engine_displacement'
      },
      // {
      //   title: 'Mileage',
      //   tag: 'mileage'
      // },
      {
        title: 'Fuel',
        tag: 'fuel'
      },
      // {
      //   title: 'Last Service Kilometers',
      //   tag: 'last_service_km'
      // },
      // {
      //   title: 'Registered On',
      //   tag: 'last_service_on'
      // }
    ],
    Features: [
      {
        title: 'Power Steering',
        logo: 'GreenTick',
        tag: 'power_steering',
      },
      {
        title: 'Power Windows Front',
        logo: 'GreenTick',
        tag: 'power_windows_front',
      },
      {
        title: 'Air Conditioner',
        logo: 'GreenTick',
        tag: 'air_conditioner',
      },
      {
        title: 'Fog Lights - Front',
        logo: 'GreenTick',
        tag: 'fog_lights_front',
      },
      {
        title: 'Alloy Wheels',
        logo: 'GreenTick',
        tag: 'alloy_wheels',
      },
      {
        title: 'Multi-function Steering Wheel',
        logo: 'GreenTick',
        tag: 'multi_function_steering_wheel',
      }
    ],
    Specification: [
      // {
      //   title: 'Max Power',
      //   tag: 'max_power'
      // },
      {
        title: 'Transmission Type',
        tag: 'transmission_type'
      },
      // {
      //   title: 'Boot Space (Litres)',
      //   tag: 'boot_space_in_ltr'
      // },
      // {
      //   title: 'Drive Type',
      //   tag: 'drive_type'
      // },
      // {
      //   title: 'Front Suspension',
      //   tag: 'front_suspension'
      // },
      // {
      //   title: 'Rear Suspension',
      //   tag: 'rear_suspension'
      // },
      // {
      //   title: 'Kerb Weight',
      //   tag: 'kerb_weight'
      // },
      // {
      //   title: 'Gross Weight',
      //   tag: 'gross_weight'
      // },
    ],
    filter: [
      // {
      //   title: 'Vehicle Type',
      //   searchBar: true,
      //   searchText: 'Search Vehicle Type',
      //   logo: DownAerrow,
      //   SubTitle: [],
      //   Unable: false,
      //   tag: 'type'
      // },
      {
        title: 'Search by Brand',
        searchBar: true,
        searchText: 'Search Brand',
        logo: DownAerrow,
        SubTitle: [],
        Unable: false,
        tag: 'brand'
      },
      {
        title: 'Search by Models',
        searchBar: true,
        searchText: 'Search Models',
        logo: DownAerrow,
        SubTitle: [],
        Unable: false,
        tag: 'model'
      },
      // {
      //   title: 'Search by Variations',
      //   searchBar: true,
      //   searchText: 'Search Variation',
      //   logo: DownAerrow,
      //   SubTitle: [],
      //   Unable: false,
      //   tag: 'varient'
      // },
      {
        title: 'Search by Budget',
        SubTitle: [1, 10000000],
        logo: DownAerrow,
        Unable: false,
        tag: 'min_price & max_price'
      },
      {
        title: 'Transmission Type',
        logo: DownAerrow,
        SubTitle: [],
        Unable: false,
        tag: 'transmission_type'
      },
      // {
      //   title: 'Fuel Type',
      //   logo: DownAerrow,
      //   SubTitle: [],
      //   Unable: false,
      //   tag: 'fuel_type'
      // },
      // {
      //   title: 'Mileage',
      //   logo: DownAerrow,
      //   SubTitle: [],
      //   Unable: false,
      //   tag: 'mileage'
      // },
      {
        title: 'Engine Displacement (cc)',
        logo: DownAerrow,
        SubTitle: [],
        Unable: false,
        tag: 'engine_sizes'
      },
      // {
      //   title: 'Number of Airbags',
      //   logo: DownAerrow,
      //   SubTitle: [],
      //   Unable: false,
      //   tag: 'min_no_of_airbags & max_no_of_airbags'
      // },
      {
        title: 'All Cars',
        logo: DownAerrow,
        SubTitle: [],
        Unable: false,
        tag: 'car_status'
      },
      {
        title: 'Overall Rating',
        logo: DownAerrow,
        SubTitle: [],
        Orginaldata: [],
        Unable: false,
        tag: 'overall_rating'
      }
    ],
    MobileHeader: [
      {
        RoutePath: '/',
        PageDeatail: 'HomePage',
        LeftLogo: 'Logo',
        Title: '101Mile',
        physical: true
      },
      {
        RoutePath: '/product-detail',
        PageDeatail: 'product detail',
        LeftLogo: 'LeftArrow',
        // RightLogo: 'Menu',
        Title: 'Motorcycle',
        physical: true
      },
      {
        RoutePath: '/history',
        PageDeatail: 'history',
        LeftLogo: 'LeftArrow',
        Title: 'Search History',
        physical: true
      },
      {
        RoutePath: '/product-comparison',
        PageDeatail: 'comparison',
        LeftLogo: 'LeftArrow',
        Title: 'Comparison',
        physical: true,
        navTo:'/product-detail'
      },
      {
        RoutePath: '/Bookmark',
        PageDeatail: 'Bookmark',
        LeftLogo: 'LeftArrow',
        Title: 'Bookmark',
        physical: true
      },
      {
        RoutePath: '/Profile',
        PageDeatail: 'Profile',
        LeftLogo: 'LeftArrow',
        // RightLogo: 'Menu',
        Title: 'My Profile',
        physical: true
      },
      {
        RoutePath: '/Comparison',
        PageDeatail: 'Comparison',
        LeftLogo: 'LeftArrow',
        Title: 'Comparison',
        physical: true
      },
      {
        RoutePath: '/aboutus',
        PageDeatail: 'About Us',
        LeftLogo: 'LeftArrow',
        Title: 'About Us',
        physical: true
      },
      {
        RoutePath: '/contactus',
        PageDeatail: 'Contact Us',
        LeftLogo: 'LeftArrow',
        Title: 'Contact Us',
        physical: true
      },
      {
        RoutePath: '/ComparisonList',
        PageDeatail: 'Comparison List',
        LeftLogo: 'LeftArrow',
        Title: 'Comparison List',
        physical: true
      },
      {
        RoutePath: '/PrivacyPolicy',
        PageDeatail: 'Privacy Policy',
        LeftLogo: 'LeftArrow',
        Title: 'Privacy Policy',
        physical: true
      },
      {
        RoutePath: '/TermsCondition',
        PageDeatail: 'Terms Condition',
        LeftLogo: 'LeftArrow',
        Title: 'Terms & Condition',
        physical: true
      },
      {
        RoutePath: '/Disclaimer',
        PageDeatail: 'Disclaimer',
        LeftLogo: 'LeftArrow',
        Title: 'Disclaimer',
        physical: true
      },
      {
        RoutePath: '/ReportBug',
        PageDeatail: 'Report Bug',
        LeftLogo: 'LeftArrow',
        Title: 'Report Bug',
        physical: true
      },
      {
        RoutePath: '/Feedback',
        PageDeatail: 'Feedback',
        LeftLogo: 'LeftArrow',
        Title: 'Feedback',
        physical: true
      },
      {
        RoutePath: '/UserReview',
        PageDeatail: 'User Review',
        LeftLogo: 'LeftArrow',
        Title: 'User Review',
        physical: true
      },
      {
        RoutePath: '/UserProfile',
        PageDeatail: 'User Profile',
        LeftLogo: 'LeftArrow',
        Title: 'User Profile',
        physical: true
      }
    ],
    NavMenu: [
      {
        title: 'Home',
        RedirectUrl: '/'
      },
      {
        title: 'Services',
        RedirectUrl: '/product-detail',
        LeftLogo: DownAerrow,
        icon: true,
        dropDowndata: [
          {
            title: 'Sell Car',
            RedirectUrl: '/product-detail/sell'
          },
          {
            title: 'Buy Car',
            RedirectUrl: '/product-detail'
          },
          {
            title: 'Comparison',
            checkUserLogin: true,
            RedirectUrl: '/product-comparison'
          },
          {
            title: 'MOT',
            click: 'Mot',
            RedirectUrl: '/Bookmark'
          },
          {
            title: 'MOT History',
            click: 'Mot History',
            RedirectUrl: '/mot_history'
          },
          {
            title: 'Road Assist',
            click: 'Road',
            RedirectUrl: '/Bookmark'
          }
        ]
      },
      {
        title: 'Bookmark',
        RedirectUrl: '/Bookmark'
      },
      {
        title: 'Search History',
        RedirectUrl: '/history'
      },
      {
        title: ['ENG', 'CHI']
      }
    ],
    MobileMenu: [
      {
        RoutePath: '/Profile',
        PageDetail: 'Profile',
        LeftLogo: 'Profile',
        Title: 'My Profile',
        physical: true
      },
      {
        RoutePath: '/history',
        PageDetail: 'history',
        LeftLogo: 'History',
        Title: 'Search History',
        physical: true
      },
      {
        RoutePath: '/Bookmark',
        PageDetail: 'Bookmark',
        LeftLogo: 'Bookmark',
        Title: 'Bookmark',
        physical: true
      },
      {
        RoutePath: '/Comparison',
        PageDetail: 'Comparison',
        LeftLogo: 'Comparison',
        Title: 'Comparison',
        physical: true
      },
      {
        RoutePath: '/aboutus',
        PageDetail: 'About Us',
        LeftLogo: 'AboutUs',
        Title: 'About Us',
        physical: true
      },
      {
        RoutePath: '/contactus',
        PageDetail: 'Contact Us',
        LeftLogo: 'ContactUs',
        Title: 'Contact Us',
        physical: true
      },
      {
        RoutePath: '/Feedback',
        PageDetail: 'Feedback',
        LeftLogo: 'Feedback',
        Title: 'Feedback',
        physical: true
      },
      {
        RoutePath: '/Logout',
        PageDetail: 'Logout',
        LeftLogo: 'Logout',
        Title: 'Logout',
        physical: true
      },
      {
        RoutePath: '/ProductDetail',
        PageDetail: 'Product Detail',
        LeftLogo: 'ProductDetail',
        Title: 'Product Detail',
        physical: false
      },
      {
        RoutePath: '/ComparisonList',
        PageDetail: 'Comparison List',
        LeftLogo: 'ComparisonList',
        Title: 'Comparison List',
        physical: true
      },
      {
        RoutePath: '/PrivacyPolicy',
        PageDetail: 'Privacy Policy',
        LeftLogo: 'PrivacyPolicy',
        Title: 'Privacy Policy',
        physical: true
      },
      {
        RoutePath: 'https://www.termsfeed.com/live/bed77b2e-fc43-476c-b37c-a1bb77e6f0af',
        PageDetail: 'Terms Condition',
        LeftLogo: 'TermsCondition',
        Title: 'Terms & Condition',
        physical: true
      },
      {
        RoutePath: '/Disclaimer',
        PageDetail: 'Disclaimer',
        LeftLogo: 'Disclaimer',
        Title: 'Disclaimer',
        physical: true
      },
      {
        RoutePath: '/ReportBug',
        PageDetail: 'Report Bug',
        LeftLogo: 'ReportBug',
        Title: 'Report Bug',
        physical: true
      },
      {
        RoutePath: '/UserReview',
        PageDetail: 'User Review',
        LeftLogo: 'UserReview',
        Title: 'User Review',
        physical: true
      },
      {
        RoutePath: '/UserProfile',
        PageDetail: 'User Profile',
        LeftLogo: 'UserProfile',
        Title: 'User Profile',
        physical: true
      }
    ],
    Footer_LogoTitle: '101Mile',
    footer: [
      {
        title: 'Home',
        RedirectUrl: '/'
      },
      {
        title: 'Services',
        RedirectUrl: '/product-detail'
      },
      {
        title: 'History',
        RedirectUrl: '/history'
      },
      {
        title: 'Comparison',
        RedirectUrl: '/product-comparison'
      },
      {
        title: 'Settings',
        Click: 'Profile Settings'
      },
      {
        title: 'Contact Us',
        RedirectUrl: '',
        other: 'contact'
      },
      {
        title: 'Privacy and Security Settings',
        RedirectUrl: 'https://www.termsfeed.com/live/bed77b2e-fc43-476c-b37c-a1bb77e6f0af'
      },
      {
        title: 'Terms and Conditions',
        RedirectUrl: 'https://www.termsfeed.com/live/bed77b2e-fc43-476c-b37c-a1bb77e6f0af'
      }
    ],
    Mobilefooter: [
      [{
        title: 'Posts',
        RedirectUrl: '/product-detail/sell',
        icon: MyPost
      },
      {
        title: 'History',
        RedirectUrl: '/history_list',
        icon: History
      }],
      [{
        title: '',
        RedirectUrl: '/'
      }],
      [{
        title: 'Bookmark',
        RedirectUrl: '/Bookmark',
        icon: mobileBookmark
      },
      {
        title: 'Profile',
        ButClick: 'Profile Settings',
        icon: ProfileSvg
      }]
    ],
    lastBaner: lastBaner,
    ProfileSettings: [
      {
        title: 'English',
        dropDown: true,
        ShowDropDownData: false,
        logo: true,
        SubMenu: [
          {
            title: 'eng',
            Event: false,
            logo: false,
            Tag: 'language',
          },
          {
            title: 'chi',
            Event: false,
            logo: false,
            Tag: 'language',
          },
        ],
      },
      {
        title: 'Settings',
        ShowDropDownData: false,
        dropDown: true,
        logo: true,
        SubMenu: [
          {
            title: 'Notifications alert',
            tag: 'notification',
            Event: true,
            logo: true,
          },
          {
            title: 'Delete account',
            tage: 'Delete',
            Event: false,
            logo: false,
          },
        ],
      },
      {
        title: 'Security & Privacy',
        dropDown: false,
        logo: true,
        link: "https://www.termsfeed.com/live/bed77b2e-fc43-476c-b37c-a1bb77e6f0af"
      },
      {
        title: 'Sign out',
        SecondTitle: 'Sign in',
        dropDown: false,
        logo: true,
      },
    ],
    Map: {
      searchLabel: 'Add Location',
      InputTitle: 'License Plate Number',
      subText: 'Our service is not available in this area, please change your location.',
      button: 'Confirm',
      SuccessMessageMap: {
        Title: `Road Assist`,
        SubTitle: `Road Assist Submitted, successfully`,
        Styling: {
          Icon: 'Success',
        }
      },
      ErrorMessageMap: {
        Title: 'Please Allow Location',
        SubTitle: 'Unable to access location, please try again',
        Styling: {
          Icon: 'Error',
        },
      },
    },
    DataALL: {
      Ratinges: {
        title: 'Rate your experience',
        SubText: 'Overall rating',
        DarkColorStarts: '#AAAAAA',
        ColorStar: '#FFC727',
        TotalStars: 5,
        Button: 'Submit rating & review',
        ReviewHolder: 'Title of your review',
        MessageHolder: 'Share the details of your experience',
        SubData: [
          {
            title: 'Mileage',
            Stars: 0,
            key: 'mileage',
          },
          {
            title: 'Maintenance Cost',
            Stars: 0,
            key: 'maintenance_cost',
          },
          {
            title: 'Safety Level',
            Stars: 0,
            key: 'safety',
          },
          {
            title: 'Features and Styling',
            Stars: 0,
            key: 'features_and_styling',
          },
          {
            title: 'Comfort',
            Stars: 0,
            key: 'comfort',
          },
          {
            title: 'Performance',
            Stars: 0,
            key: 'performance',
          },
        ],
      },
      postData: {
        title: 'Add new post',
        editPost: 'Edit Post',
        PhotoTitle: 'Add photos & video',
        Button: 'Post',
        Holder: [
          {
            title: 'Submit on Behalf of',
            Icon: DownAerrow,
            typeof: 'text',
            tags: 'owner'
          },
          {
            title: 'Select Type',
            Icon: DownAerrow,
            typeof: 'text',
            tags: 'type',
            important: true,
          },
          {
            title: 'Brand/Make',
            Icon: DownAerrow,
            typeof: 'text',
            tags: 'brand',
            important: true,
          },
          {
            title: 'Model',
            important: true,
            Icon: DownAerrow,
            typeof: 'text',
            tags: 'model',
          },
          // {
          //   title: 'Variant',
          //   Icon: DownAerrow,
          //   typeof: 'text',
          //   tags: 'varient',
          //   important: true,
          // },
          {
            title: 'Year',
            typeof: 'number',
            tags: 'year',
            important: true,
          },
          // {
          //   title: 'Engine size',
          //   // Icon: DownAerrow,
          //   dropDown: [],
          //   typeof: 'text',
          //   tags: 'engine',
          //   important: true,
          // },
          {
            title: 'Seat',
            Icon: DownAerrow,
            typeof: 'number',
            tags: 'seat_count',
            important: true,
          },
          {
            title: 'Price',
            typeof: 'number',
            tags: 'price',
            important: true,
          },
          {
            title: 'Description',
            typeof: 'string',
            tags: 'description',
          },
          {
            title: 'KM Driven',
            typeof: 'number',
            tags: 'km_driven',
          },
          // {
          //   title: 'History',
          //   Icon: DownAerrow,
          //   typeof: 'string',
          //   dropDown: [],
          //   tags: 'accidental_history',
          // },
          // {
          //   title: 'Last Service On',
          //   typeof: 'date',
          //   tags: 'last_service_on',
          // },
          // {
          //   title: 'Last Service KM',
          //   typeof: 'number',
          //   tags: 'last_service_km',
          // },
          // {
          //   title: 'Registration Number',
          //   typeof: 'string',
          //   tags: 'registration_number',
          // },
          // {
          //   title: 'Registration Number Series',
          //   typeof: 'string',
          //   tags: 'registration_number_series',
          // },
          // {
          //   title: 'Registered In',
          //   typeof: 'date',
          //   tags: 'registered_in',
          // },
          {
            title: 'Features',
            holder: 'Select Features',
            features: 'MultiDropdown',
            typeof: 'string',
            tags: 'array',
            Icon: DownAerrow,
            dropDown: [
              {
                title: 'Power Steering',
                tags: 'power_steering',
              },
              {
                title: 'Air Conditioner',
                tags: 'air_conditioner',
              },
              {
                title: 'Alloy Wheel',
                tags: 'alloy_wheel',
              },
              {
                title: 'Power Windows Front',
                tags: 'power_windows_front',
              },
              {
                title: 'Fog Lights Front',
                tags: 'fog_lights_front',
              },
              {
                title: 'Multi Function Steering Wheel',
                tags: 'multi_function_steering_wheel',
              },
            ],
          },
          // {
          //   title: 'Max Power',
          //   typeof: 'string',
          //   tags: 'max_power',
          // },
          // {
          //   title: 'Transmission Type',
          //   typeof: 'string',
          //   tags: 'transmission_type',
          // },
          {
            title: 'Transmission Type',
            typeof: 'string',
            tags: 'transmission_type',
          },
          // {
          //   title: 'Boot Space In Ltr',
          //   typeof: 'number',
          //   tags: 'boot_space_in_ltr',
          // },
          // {
          //   title: 'Drive Type',
          //   typeof: 'string',
          //   tags: 'drive_type',
          // },
          // {
          //   title: 'Front Suspension',
          //   typeof: 'string',
          //   tags: 'front_suspension',
          // },
          // {
          //   title: 'Rear Suspension',
          //   typeof: 'string',
          //   tags: 'rear_suspension',
          // },
          // {
          //   title: 'Kerb Weight',
          //   typeof: 'number',
          //   tags: 'kerb_weight',
          // },
          // {
          //   title: 'Gross Weight',
          //   typeof: 'number',
          //   tags: 'gross_weight',
          // },
          {
            title: 'Fuel',
            typeof: 'string',
            tags: 'fuel',
          },
          // {
          //   title: 'Mileage',
          //   typeof: 'number',
          //   tags: 'mileage',
          // },
          {
            title: 'Engine Displacement (CC)',
            typeof: 'number',
            tags: 'engine_displacement',
          }
        ]
      },
      MOTDetails: {
        title: 'Add MOT details',
        PhotoTitle: 'Upload VRD',
        PhotoTitleSucess: 'DOC Uploaded Successfully',
        Button: 'Confirm',
        Step1Success: {
          Title: 'MOT Step 1 Added Successfully',
          SubTitle: 'Please continue with step 2 to complete the process.',
          Styling: {
            Icon: 'Success',
          },
        },
        Holder: [
          {
            title: 'Year',
            typeof: 'number',
            tags: 'year',
            important: true,
          },
          {
            title: 'Make',
            Icon: DownAerrow,
            typeof: 'string',
            dropDown: [],
            important: true,
            tags: 'make',
          },
          {
            title: 'Model',
            Icon: DownAerrow,
            typeof: 'string',
            important: true,
            dropDown: [],
            tags: 'model',
          },
          {
            title: 'Additional Details',
            typeof: 'string',
            tags: 'status_reason',
          },
          {
            title: 'Engine Displacement (cc)',
            important: true,
            typeof: 'number',
            tags: 'engine_displacement',
          },
        ],
      },
      Schedule: {
        title: 'Schedule',
        Button: 'Send',
        Step2Success: {
          Title: 'MOT Process Completed Successfully',
          SubTitle: 'We will contact you as soon as possible.',
          Styling: {
            Icon: 'Success',
          },
        },
        Holder: [
          {
            title: 'Date',
            typeof: 'date',
            tags: 'date',
            range: { min: '1899-01-01', max: '2023-12-31' },
          },
          {
            title: 'Time',
            typeof: 'time',
            tags: 'time',
          },
          {
            title: 'Location',
            typeof: 'string',
            tags: 'place',
          },
        ],
      },
    },
    Starcolor: {
      color: 'red',
      StarNumber: 5,
    },
    PopUpData: {
      ErrorMessage: {
        mobileNumber: {
          Title: 'Invalid Phone Number',
          SubTitle: 'Please enter a valid phone number',
          Styling: {
            Icon: 'Error',
          },
        },
        country: {
          Title: 'Invalid Country Address',
          SubTitle: 'Please select a valid country from the list',
          Styling: {
            Icon: 'Error',
          },
        },
        DealeatedUser: {
          Title: 'User Deleted',
          SubTitle: 'User account deleted. Contact customer care at 101 Mile for help.',
          Styling: {
            Icon: 'Error',
          },
        },
        WrongPassword: {
          Title: 'Wrong Password!',
          SubTitle: 'Please enter a valid password or go through the forget password process',
          Styling: {
            Icon: 'Error',
          },
        },
        password: {
          Title: 'Invalid Password',
          SubTitle: 'Please enter a valid password',
          Styling: {
            Icon: 'Error',
          },
        },
        RemovedProduct: {
          Title: 'Removed From Comparison',
          SubTitle: 'Removed from comparison, check another product',
          Styling: {
            Icon: 'Error',
          },
        },
        AllDataError: {
          Title: 'Please Try Again',
          SubTitle: 'Invalid data: Phone Number, Country & Password',
          Styling: {
            Icon: 'Error',
          },
        },
        LocationError: {
          Title: 'Please Allow Location',
          SubTitle: 'Unable to access location, please try again',
          Styling: {
            Icon: 'Error',
          },
        },
        InternetError: {
          Title: 'Please Allow Internet',
          SubTitle: 'Unable to access the internet, please try again',
          Styling: {
            Icon: 'Error',
          },
        },
        Bookmark: {
          Title: 'Removed Bookmark',
          SubTitle: 'Your bookmark has been removed',
          Styling: {
            Icon: 'Error',
          },
        },
        LoginBookMark: {
          Title: 'Please Login',
          SubTitle: 'Please login to your account',
          Styling: {
            Icon: 'Error',
          },
        },
        NoticeUserToLogin: {
          Title: 'Please Login',
          SubTitle: 'Please login to your account',
          Styling: {
            Icon: 'Error',
          },
        },
      },
      SuccessMessage: {
        ConformNumber: {
          Title: `Phone No. Confirmed!`,
          SubTitle: `Continue with verification process, please verify your number.`,
          Styling: {
            Icon: 'Success',
          }
        },
        Coparision: {
          Title: `Added To Comparison`,
          SubTitle: `Car added to ‘Comparison’.`,
          Styling: {
            Icon: 'Success',
          }
        },
        LoginWithNumber: {
          Title: `Welcome to 101 Mile`,
          SubTitle: `Get Your Dream Car On Track`,
          Styling: {
            Icon: 'Success',
          },
        },
        OTPNumber: {
          Title: `OTP Verification`,
          SubTitle: `OTP verification done successfully`,
          Styling: {
            Icon: 'Success',
          },
        },
        ConfirmNumber: {
          Title: `Phone No. Confirmed!`,
          SubTitle: `Continue with verification process, please verify your number.`,
          Styling: {
            Icon: 'Success',
          },
        },
        passwordNumber: {
          Title: `Welcome to 101 Mile`,
          SubTitle: `Register process done successfully, get your dream car on track.`,
          Styling: {
            Icon: 'Success',
          },
        },
        GetinTouch: {
          Title: 'Team Will Contact',
          SubTitle: 'Team will contact the user via email or phone promptly.',
          Styling: {
            Icon: 'Success',
          },
        },
        Bookmark: {
          Title: `Added Your Favorite`,
          SubTitle: `Your favorite car has been added to ‘Bookmark’, please visit ‘Bookmark’.`,
          Styling: {
            Icon: 'Success',
          },
        },
      },
    },
    BookmarkData: {
      MainBanner: cars[0],
      subtitle: "Bookmark Vehicle",
      NoData: {
        title: 'You haven’t added any bookmarks yet',
        subtitle: 'Would you like to add some of your favorites to your bookmarks?',
        button: 'Add Bookmark',
      },
      Cards: {
        statusContent: 'Sold',
        stockAvailable: 'In Stock',
        OutOfStock: 'Out of Stock',
        button: 'View Details',
        review: 'Review'
      },
    },
    CardData: {
      MainBanner: cars[0],
      subtitle: "History information about a vehicle",
      NoData: {
        title: 'You’ve not added any bookmark yet',
        subtitle: 'Would you like to add some of your favourites in your bookmark?',
        button: 'Add bookmark'
      },
      LargeCard: {
        statusContent: 'Sold',
        stockAvailable: 'In Stock',
        OutOfStock: 'Out of Stock',
        button: 'CHECK DETAILS',
        review: 'Review'
      },
      Cards: {
        statusContent: 'Sold',
        stockAvailable: 'In Stock',
        OutOfStock: 'Out of Stock',
        button: 'View Details',
        review: 'Review'
      },
    },
    SearchCar: {
      MainBanner: cars[0],
      subtitle: "Search Information About a Vehicle",
      NoData: {
        title: 'You’ve Search Not found',
        subtitle: 'Would you like to search some of your favourites',
        button: 'Search'
      },
      Cards: {
        statusContent: 'Sold',
        stockAvailable: 'In Stock',
        OutOfStock: 'Out of Stock',
        button: 'View Details',
        review: 'Review'
      },
    },
    carscompare: {
      MainTitle: 'Comparing ',
      SubTitle: '',
      titles: ['Brand', 'Price', 'Ratings', 'Km Driven', 'Body Type', 'Engine', 'Fuel Type', 'Max Power'],
    },
    dataforlogn: {
      LoginWithSuport: {
        Title: 'Login with your details',
        SubTitle: 'To explore and enjoy our services!',
        Skip: 'Skip for now >>',
        content: [
          {
            title: 'Continue with Phone',
            Icon: mbile,
            Info: 'Numbered',
          },
          {
            title: 'Continue with Google',
            Icon: google,
            Info: 'Google',
          },
          {
            title: 'Continue with Facebook',
            Icon: fb,
            Info: 'Facebook',
          },
        ],
      },
      LoginWithNumber: {
        Title: 'Enter your login details',
        SubTitle: 'Add your number & password to login',
        DefaultCountry: 'India',
        ForgetPass: 'Forgot Password?',
        content: {
          Number: 'true',
          Password: true,
          PhoneNumberHolder: 'Phone Number',
          PassHolder: 'Password',
        },
        tag: 'Login_Info',
        Button: 'Login',
      },
      ConformNumber: {
        Title: 'Confirm your number',
        SubTitle: 'Will share you OTP to reset password',
        DefaultCountry: 'India',
        content: {
          Number: 'true',
          Password: false,
          PhoneNumberHolder: 'Phone Number',
          PassHolder: 'Password',
        },
        tag: 'Login_Info',
        ConformScreen: 'Confirm',
        Button: 'Confirm',
      },
      VerifyOtp: {
        Title: 'Verification',
        SubTitle: 'Enter 4 digit OTP received on',
        footer: 'Didn’t receive the OTP?',
        opt: 'Resend in seconds',
        content: {
          Otp: true,
        },
        Button: 'Continue',
      },
      resetPass: {
        Title: 'Enter a New Password',
        SubTitle: 'Enter and confirm your new password',
        content: {
          Password: true,
          PassHolder: 'New Password',
          ConfirmPass: 'Confirm New Password',
        },
        tag: 'Login_Info',
        Button: 'Done'
      },
    },
    SliderImageData: {
      sliderButton: 'Your Best Choice!',
      sliderButtonLink: '',
    },
    Services: {},
    CardsData: {
      Title: 'Our Services',
      RightText: '', // See all
      RightTextLink: '/product-detail',
      ErrorLogs: {
        Title: 'Please Login',
        SubTitle: 'Please login to your account',
        Styling: {
          Icon: 'Error',
        },
      },
      cards: [
        {
          title: 'Buy and Sell',
          subtitle: 'Best car for Sell and Buy',
          bodycontent: 'KTM is one of the finest sports bikes in India. It has a powerful engine.',
          cardImage: 'https://wallpaperaccess.com/full/3460572.jpg',
          button: 'Find perfect ride',
          ButtonLink: '/product-detail',
          tags: 'Ride',
        },
        {
          title: 'MOT - Any make and model',
          subtitle: 'Best car for Sell and Buy',
          bodyContent: 'KTM is one of the finest sports bikes in India. It has a powerful engine.',
          cardImage: 'https://wallpaperaccess.com/full/2056566.jpg',
          button: 'Book a Slot',
          tags: 'MOT',
          ErrorLogs: {
            Title: 'Please Login',
            SubTitle: 'Please login to your account',
            Styling: {
              Icon: 'Error',
            },
          },
          requestSuccess: {
            Title: 'Request Success',
            SubTitle: 'We will get back to you shortly',
            Styling: {
              Icon: 'Error',
            },
          },
        },
        {
          title: 'Road assist management',
          subtitle: 'Best car for Sell and Buy',
          bodyContent: 'KTM is one of the finest sports bikes in India. It has a powerful engine.',
          cardImage: 'https://www.xtrafondos.com/wallpapers/resized/volante-de-bmw-m6-2327.jpg?s=large',
          button: 'Check it now!',
          tags: 'Map',
        },
      ],
    },
  },
  chi: {
    welcomeMsg: 'Sharing smile through servicing',
    InternetIssuesMsg: `Everything's fine, the internet is thriving!`,
    Loading: 'Loading...',
    ServerError: '404 Page Not Found',
    HomePageSignIn: '登入',
    HomePageSignOut: 'Sign Out',
    HomePageSearch: '搜尋',
    defaultName: '顧客名稱',
    productCards: {
      SortBy: '排序方式',
      clearFilter: '清除所有篩選',
      sell: {
        title: '出售',
        tag: 'Sell'
      },
      buy: {
        title: '購買',
        tag: 'Buy'
      },
    },
    productdata: {
      WriteReview: '寫評論',
      CallMeet: '約見',
      Button: 'ADD TO COMPARE',
      Status: 'Available in stock',
      userReview: 'User Reviews'
    },
    productTab: [
      {
        mainTitle: '概述',
        Title: 'Overview',
        Unable: true,
      }, {
        mainTitle: '特點',
        Title: 'Features',
        Unable: false
      }, {
        mainTitle: '規格',
        Title: 'Specification',
        Unable: false
      }
    ],
    inetialPage: [
      {
        title: '車主',
        tag: 'owner'
      },
      {
        title: '年份',
        tag: 'year'
      },
      // {
      //   title: '歷史',
      //   tag: 'history'
      // },
      {
        title: '行駛公里數',
        tag: 'km_driven'
      },
      // {
      //   title: '註冊',
      //   tag: 'registered_in'
      // },
      {
        title: '容積(cc)',
        tag: 'engine_displacement'
      },
      // {
      //   title: 'Mileage',
      //   tag: 'mileage'
      // },
      {
        title: 'fuel',
        tag: 'fuel'
      },
      // {
      //   title: '最近一次服務',
      //   tag: 'last_service_km'
      // },
      // {
      //   title: '註冊於',
      //   tag: 'last_service_on'
      // }
    ],
    Features: [
      {
        title: 'Power Steering',
        logo: 'GreenTick',
        tag: 'power_steering',
      },
      {
        title: 'Power Windows Front',
        logo: 'GreenTick',
        tag: 'power_windows_front',
      },
      {
        title: 'Air Conditioner',
        logo: 'GreenTick',
        tag: 'air_conditioner',
      },
      {
        title: 'Fog Lights - Front',
        logo: 'GreenTick',
        tag: 'fog_lights_front',
      },
      {
        title: 'Alloy Wheels',
        logo: 'GreenTick',
        tag: 'alloy_wheels',
      },
      {
        title: 'Multi-function Steering Wheel',
        logo: 'GreenTick',
        tag: 'multi_function_steering_wheel',
      }
    ],
    Specification: [
      // {
      //   title: 'Max Power',
      //   tag: 'max_power'
      // },
      {
        title: 'TransmissionType',
        tag: 'transmission_type'
      },
      // {
      //   title: 'Boot Space (Litres)',
      //   tag: 'boot_space_in_ltr'
      // },
      // {
      //   title: 'Drive Type',
      //   tag: 'drive_type'
      // },
      // {
      //   title: 'Front Suspension',
      //   tag: 'front_suspension'
      // },
      // {
      //   title: 'Rear Suspension',
      //   tag: 'rear_suspension'
      // },
      // {
      //   title: 'Kerb Weight',
      //   tag: 'kerb_weight'
      // },
      // {
      //   title: 'Gross Weight',
      //   tag: 'gross_weight'
      // },
    ],
    filter: [
      // {
      //   title: '車輛型別',
      //   logo: DownAerrow,
      //   SubTitle: [],
      //   Unable: false,
      //   tag: 'type'
      // },
      {
        title: '按品牌搜尋',
        logo: DownAerrow,
        SubTitle: [],
        Unable: false,
        tag: 'brand'
      },
      {
        title: 'Search by models',
        logo: DownAerrow,
        SubTitle: [],
        Unable: false,
        tag: 'model'
      },
      // {
      //   title: 'Search by Variations',
      //   logo: DownAerrow,
      //   SubTitle: [],
      //   Unable: false,
      //   tag: 'varient'
      // },
      {
        title: '按預算搜尋',
        SubTitle: [1, 10000000],
        logo: DownAerrow,
        Unable: false,
        tag: 'min_price & max_price'
      },
      {
        title: '傳動（自動波/棍波)',
        logo: DownAerrow,
        SubTitle: [],
        Unable: false,
        tag: 'transmission_type'
      },
      // {
      //   title: '燃料型別',
      //   logo: DownAerrow,
      //   SubTitle: [],
      //   Unable: false,
      //   tag: 'fuel_type'
      // },
      // {
      //   title: '里數',
      //   logo: DownAerrow,
      //   SubTitle: [],
      //   Unable: false,
      //   tag: 'mileage'
      // },
      {
        title: '容積(cc)',
        logo: DownAerrow,
        SubTitle: [],
        Unable: false,
        tag: 'min_engine_displacement & max_engine_displacement'
      },
      // {
      //   title: '安全氣囊',
      //   logo: DownAerrow,
      //   SubTitle: [],
      //   Unable: false,
      //   tag: 'min_no_of_airbags & max_no_of_airbags'
      // },
      {
        title: '評級',
        logo: DownAerrow,
        SubTitle: [],
        Orginaldata: [],
        Unable: false,
        tag: 'overall_rating'
      }
    ],
    MobileHeader: [
      {
        RoutePath: '/',
        PageDeatail: 'HomePage',
        LeftLogo: Logo,
        Title: '101Mile',
        physical: true
      },
      {
        RoutePath: '/product-detail',
        PageDeatail: 'product detail',
        LeftLogo: LeftAerrow,
        // RightLogo: Menu,
        Title: '電單車',
        physical: true
      },
      {
        RoutePath: '/history',
        PageDeatail: 'history',
        LeftLogo: LeftAerrow,
        Title: '搜尋紀錄',
        physical: true
      },
      {
        RoutePath: '/Bookmark',
        PageDeatail: 'Bookmark',
        LeftLogo: LeftAerrow,
        Title: 'Bookmark',
        physical: true
      },
      {
        RoutePath: '/product-comparison',
        PageDeatail: 'product comparison',
        LeftLogo: LeftAerrow,
        Title: 'Product Comparison',
        physical: true
      },
      {
        RoutePath: '/product-detail/',
        PageDeatail: 'product detail',
        LeftLogo: LeftAerrow,
        Title: 'Product Detail',
        physical: false
      },
    ],
    NavMenu: [
      {
        title: '主頁',
        RedirectUrl: '/'
      },
      {
        title: '服務',
        RedirectUrl: '/service',
        LeftLogo: DownAerrow,
        icon: true,
        dropDowndata: [
          {
            title: 'Sell Car',
            RedirectUrl: '/product-detail/sell'
          }, {
            title: 'Buy Car',
            RedirectUrl: '/product-detail'
          }, {
            title: 'Comparison',
            RedirectUrl: '/product-comparison'
          }, {
            title: 'MOT',
            click: 'Mot',
            IsLogin: 'check'
          }, {
            title: 'Road assist',
            click: 'Road',
            IsLogin: 'check'
          }
        ]
      },
      {
        title: 'Bookmark',
        RedirectUrl: '/Bookmark'
      },
      // {
      // title: 'Product Detail',
      // RedirectUrl: '/product-detail'
      // },
      {
        title: '搜尋紀錄',
        RedirectUrl: '/history'
      },
      // {
      // title: '分享',
      // RedirectUrl: '/404'
      // },
      {
        title: ['英', '中']
      }
    ],
    Footer_LogoTitle: '101Mile',
    footer: [
      {
        title: '主頁',
        RedirectUrl: '/'
      },
      {
        title: '服務',
        RedirectUrl: '/service'
      },
      {
        title: '搜尋紀錄',
        RedirectUrl: '/history'
      },
      {
        title: '比較',
        RedirectUrl: '/product-comparison'
      },
      {
        title: '設定',
        Click: 'Profile Settings'
      },
      {
        title: '聯絡我們',
        RedirectUrl: '/'
      },
      {
        title: '私人及安全設定',
        RedirectUrl: '/'
      },
      {
        title: '條款及細則',
        RedirectUrl: '/'
      }
    ],
    Mobilefooter: [
      [{
        title: '貼文',
        RedirectUrl: '/product-detail/sell',
        icon: MyPost
      },
      {
        title: '搜尋紀錄',
        RedirectUrl: '/history',
        icon: History
      }],
      [{
        title: '',
        RedirectUrl: '/'
      }],
      [{
        title: 'Bookmark',
        RedirectUrl: '/Bookmark',
        icon: mobileBookmark
      },
      {
        title: 'Profile',
        ButClick: 'Profile Settings',
        icon: ProfileSvg
      }]
    ],
    lastBaner: lastBannerCHI,
    ProfileSettings: [
      {
        title: '英文',
        dropDown: true,
        ShowDropDownData: false,
        logo: true,
        SubMenu: [{
          title: 'eng',
          Event: false,
          logo: false,
          Tag: 'language',
        }, {
          title: '中',
          Event: false,
          logo: false,
          Tag: 'language',
        }
        ]
      },
      {
        title: '設定',
        ShowDropDownData: false,
        dropDown: true,
        logo: true,
        SubMenu: [{
          title: 'Notifications alert',
          tag: 'notification',
          Event: true,
          logo: true,
        }, {
          title: 'Delete account',
          tage: 'Delete',
          Event: false,
          logo: false,
        }
        ]
      },
      {
        title: 'Security & Privacy',
        dropDown: false,
        logo: true,
        link: "https://www.termsfeed.com/live/bed77b2e-fc43-476c-b37c-a1bb77e6f0af"
      },
      {
        title: '登出',
        SecondTitle: '登入',
        dropDown: false,
        logo: true,
      },
    ],
    Map: {
      searchLabel: '新增位置',
      InputTitle: '車牌號碼',
      subText: '我們的服務在這個地區未能使用，請更改您的位置。',
      button: '確認'
    },
    DataALL: {
      Ratinges: {
        title: '體驗評價',
        SubText: '總評分',
        DarkColorStarts: '#AAAAAA',
        ColorStar: '#FFC727',
        TotalStars: 5,
        Button: '提交評級和評論',
        ReviewHolder: '評論標題',
        MessageHolder: '分享你的體驗細節',
        SubData: [
          {
            title: '里程',
            Stars: 0,
            key: 'mileage'
          },
          {
            title: '維修紀錄成本',
            Stars: 0,
            key: 'maintenance_cost'
          },
          {
            title: '安全程度',
            Stars: 0,
            key: 'safety'
          },
          {
            title: '特點與性能',
            Stars: 0,
            key: 'features_and_styling'
          },
          {
            title: '舒適度',
            Stars: 0,
            key: 'comfort'
          },
          {
            title: '效能度',
            Stars: 0,
            key: 'performance'
          }
        ]
      },
      postData: {
        title: '新增帖子',
        PhotoTitle: '新增相片及影片',
        Button: '貼文',
        Holder: [
          {
            title: 'Submit on Behalf of',
            Icon: DownAerrow,
            typeof: 'text',
            tags: 'owner'
          },
          {
            title: '選擇型號唔同',
            Icon: DownAerrow,
            typeof: 'text',
            tags: 'type',
            important: true,
          },
          {
            title: '品牌',
            Icon: DownAerrow,
            typeof: 'text',
            tags: 'brand',
            important: true,
          },
          {
            title: '型號',
            Icon: DownAerrow,
            typeof: 'text',
            tags: 'model',
            important: true,
          },
          // {
          //   title: 'Variant',
          //   Icon: DownAerrow,
          //   typeof: 'text',
          //   tags: 'varient',
          //   important: true,
          // },
          {
            title: '年份',
            Icon: DownAerrow,
            typeof: 'number',
            dropDown: [],
            tags: 'year',
            important: true,
          },
          {
            title: '容積(cc)',
            Icon: DownAerrow,
            typeof: 'text',
            tags: 'engine',
            important: true,
          },
          {
            title: '座位',
            Icon: DownAerrow,
            typeof: 'number',
            tags: 'seat_count',
            important: true,
          },
          {
            title: '價格',
            typeof: 'number',
            tags: 'price',
            important: true,
          },
          {
            title: 'Description',
            typeof: 'string',
            tags: 'description',
          },
          {
            title: 'KM Driven',
            typeof: 'number',
            tags: 'km_driven',
          },
          // {
          //   title: '搜尋紀錄',
          //   Icon: DownAerrow,
          //   typeof: 'string',
          //   dropDown: [],
          //   tags: 'accidental_history',
          // },
          // {
          //   title: 'Last Service On',
          //   typeof: 'date',
          //   tags: 'last_service_on',
          // },
          // {
          //   title: 'Last Service KM',
          //   typeof: 'number',
          //   tags: 'last_service_km',
          // },
          // {
          //   title: 'Registration Number',
          //   typeof: 'string',
          //   tags: 'registration_number',
          // },
          // {
          //   title: 'Registration Number Series',
          //   typeof: 'string',
          //   tags: 'registration_number_series',
          // },
          // {
          //   title: '註冊於',
          //   typeof: 'date',
          //   tags: 'registered_in',
          // },
          {
            title: '特點',
            features: 'MultiDropdown',
            typeof: 'string',
            tags: 'array',
            Icon: DownAerrow,
            dropDown: [
              {
                title: 'Air Conditioner',
                tags: 'air_conditioner',
              },
              {
                title: 'Alloy Wheel',
                tags: 'alloy_wheel',
              },
              {
                title: 'Power Windows Front',
                tags: 'power windows front',
              },
              {
                title: 'Fog Lights Front',
                tags: 'fog lights front',
              },
              {
                title: 'Multi Function Steering Wheel',
                tags: 'multi_function_steering_wheel',
              },
            ],
          },
          // {
          //   title: '最大功率',
          //   typeof: 'string',
          //   tags: 'max_power',
          // },
          {
            title: '傳輸型別',
            typeof: 'string',
            tags: 'transmission_type',
          },
          // {
          //   title: '尾箱容量',
          //   typeof: 'number',
          //   tags: 'boot_space_in_ltr',
          // },
          // {
          //   title: 'Drive Type',
          //   typeof: 'string',
          //   tags: 'drive_type',
          // },
          {
            title: '前避震',
            typeof: 'string',
            tags: 'front_suspension',
          },
          // {
          //   title: '後避震',
          //   typeof: 'string',
          //   tags: 'rear_suspension',
          // },
          // {
          //   title: 'Kerb Weight',
          //   typeof: 'number',
          //   tags: 'kerb_weight',
          // },
          // {
          //   title: '車重量',
          //   typeof: 'number',
          //   tags: 'gross_weight',
          // },
        ]
      },
      MOTDetails: {
        title: '新增驗車詳細資料',
        PhotoTitle: '上傳牌簿',
        PhotoTitleSucess: 'DOC Uploaded Successfully',
        Button: '確認',
        Step1Success: {
          Title: 'MOT Step 1 Added Successfully',
          SubTitle: 'Please continue with step 2 to complete the process.',
          Styling: {
            Icon: 'Success',
          }
        },
        Holder: [
          {
            title: '年份',
            typeof: 'number',
            important: true,
            tags: 'year'
          }, {
            title: '品牌',
            Icon: DownAerrow,
            typeof: 'string',
            important: true,
            dropDown: [],
            tags: 'make'
          }, {
            title: '型號',
            Icon: DownAerrow,
            important: true,
            typeof: 'string',
            dropDown: [],
            tags: 'model'
          }, {
            title: 'Additional Details ( if any )',
            typeof: 'string',
            tags: 'status_reason'
          }, {
            title: '容積(cc)',
            important: true,
            typeof: 'string',
            tags: 'engine_displacement'
          }
        ]
      },
      Schedule: {
        title: '安排',
        Button: 'Send',
        Step2Success: {
          Title: 'MOT Process Completed Successfully',
          SubTitle: 'We will contact you as soon as possible.',
          Styling: {
            Icon: 'Success',
          }
        },
        Holder: [
          {
            title: '日子',
            typeof: 'date',
            tags: 'date',
            range: { min: '1899-01-01', max: '2023-12-31' }
          }, {
            title: '時間',
            typeof: 'time',
            tags: 'time'
          }, {
            title: '地方',
            typeof: 'string',
            tags: 'place'
          }
        ]
      },
    },
    Starcolor: {
      color: 'red',
      StarNumber: 5
    },
    PopUpData: {
      ErrorMessage: {
        mobileNumber: {
          Title: 'Invalid phone number',
          SubTitle: 'Please enter a valid phone number',
          Styling: {
            Icon: 'Error',
          }
        },
        country: {
          Title: 'Invalid Country address',
          SubTitle: 'Please Selected Valid country From List',
          Styling: {
            Icon: 'Error',
          }
        },
        DealeatedUser: {
          Title: 'User Deleted',
          SubTitle: 'User account deleted. Contact customer care at 101 Mile for help.',
          Styling: {
            Icon: 'Error',
          }
        },
        WrongPassword: {
          Title: 'Wrong Password!!',
          SubTitle: 'Please Enter a valid password or Go through forget password',
          Styling: {
            Icon: 'Error',
          }
        },
        password: {
          Title: 'Invalid password',
          SubTitle: 'Please enter a valid password',
          Styling: {
            Icon: 'Error',
          }
        },
        RemovedProduct: {
          Title: 'Removed From Comparison',
          SubTitle: 'Removed from Comparison, Check another product',
          Styling: {
            Icon: 'Error',
          }
        },
        AllDataError: {
          Title: 'Please Try Again',
          SubTitle: 'Invalid DataPhone Number, Country & password',
          Styling: {
            Icon: 'Error',
          }
        },
        LocationError: {
          Title: 'Please Alow Location',
          SubTitle: 'Unable to acess location, please try again',
          Styling: {
            Icon: 'Error',
          }
        },
        InterNetError: {
          Title: 'Please Alow Internet',
          SubTitle: 'Unable to acess Internet, please try again',
          Styling: {
            Icon: 'Error',
          }
        },
        BookMark: {
          Title: 'Removed Bookmark',
          SubTitle: 'Your Bookmark has been removed',
          Styling: {
            Icon: 'Error',
          }
        },
        LoginBookMark: {
          Title: 'Please Login',
          SubTitle: 'Please login to your account',
          Styling: {
            Icon: 'Error',
          }
        },
        NoticeUserToLogin: {
          Title: 'Please Login',
          SubTitle: 'please login to your account',
          Styling: {
            Icon: 'Error',
          }
        },
      },
      SuccessMessage: {
        Coparision: {
          Title: `Added To Comparison`,
          SubTitle: `Car added to ‘Comparison’, please visit ‘Comparison’.`,
          Styling: {
            Icon: 'Success',
          }
        },
        LoginWithNumber: {
          Title: `Welcome to 101 MILE`,
          SubTitle: `Get Your Dream Car On Track`,
          Styling: {
            Icon: 'Success',
          }
        },
        OTPNumber: {
          Title: `OTP Verification`,
          SubTitle: `OTP verification done successfully`,
          Styling: {
            Icon: 'Success',
          }
        },
        ConformNumber: {
          Title: `Phone No. Confirmed!`,
          SubTitle: `Continue with verification process, please verify your number.`,
          Styling: {
            Icon: 'Success',
          }
        },
        passwordNumber: {
          Title: `Welcome to 101 MILE`,
          SubTitle: `Register process done sucessfully, get your dream car on track.`,
          Styling: {
            Icon: 'Success',
          }
        },
        GetinTouch: {
          Title: 'Team Will Contact',
          SubTitle: 'Team will contact the user via email or phone promptly.',
          Styling: {
            Icon: 'Success',
          }
        },
        BookMark: {
          Title: `Added your favorite`,
          SubTitle: `Your favorite car has been added to ‘Bookmark’, please visit ‘Bookmark’.`,
          Styling: {
            Icon: 'Success',
          }
        }
      }
    },
    BookmarkData: {
      MainBanner: cars[0],
      subtitle: "Bookmark vehicle",
      NoData: {
        title: 'You’ve not added any bookmark yet',
        subtitle: 'Would you like to add some of your favourites in your bookmark?',
        button: 'Add bookmark'
      },
      Cards: {
        statusContent: '售出',
        stockAvailable: '現貨',
        OutOfStock: '已售',
        button: '檢視詳細資訊',
        review: 'Review'
      }
    },
    CardData: {
      MainBanner: cars[0],
      subtitle: "History information about a vehicle",
      // subbanner: <HomePageBanner data={lastBaner} />,
      NoData: {
        title: 'You’ve not added any bookmark yet',
        subtitle: 'Would you like to add some of your favourites in your bookmark?',
        button: 'Add bookmark'
      },
      LargeCard: {
        statusContent: '售出',
        stockAvailable: '現貨',
        OutOfStock: '已售',
        button: '檢視詳細資訊',
        review: '評論'
      },
      Cards: {
        statusContent: '售出',
        stockAvailable: '現貨',
        OutOfStock: '已售',
        button: '檢視詳細資訊',
        review: '評論'
      }
    },
    SearchCar: {
      MainBanner: cars[0],
      subtitle: "Search information about a vehicle's",
      NoData: {
        title: 'You’ve Search Not found',
        subtitle: 'Would you like to search some of your favourites',
        button: '搜尋'
      },
      Cards: {
        statusContent: '售出',
        stockAvailable: '現貨',
        OutOfStock: '已售',
        button: '檢視詳細資訊',
        review: '評論'
      }
    },
    carscompare: {
      MainTitle: '比較 ',
      SubTitle: '',
      titles: ['Brand', 'Price', 'Ratings', 'Body Type', 'Mileage', 'Power', 'Engine', 'Fuel Type', 'Fuel Cunsumption', 'Max Power'],
    },
    dataforlogn: {
      LoginWithSuport: {
        Title: '個人資料登入',
        SubTitle: '搜尋及享用服務',
        Skip: '跳過 >>',
        content: [
          {
            title: '繼續使用電話',
            Icon: mbile,
            Info: 'Numbered'
          },
          {
            title: '繼續使用谷歌',
            Icon: google,
            Info: 'Google'
          },
          {
            title: '繼續使用臉書',
            Icon: fb,
            Info: 'fb'
          }
        ]
      },
      LoginWithNumber: {
        Title: '輸入個人資料',
        SubTitle: '輸入你的電話號碼及密碼',
        DefaultCountry: 'India',
        ForgetPass: '忘記密碼?',
        content: {
          Number: 'true',
          Password: true,
          PhoneNumberHolder: '電話號碼',
          PassHolder: '密碼'
        },
        tag: 'Login_Info',
        Button: '登入'
      },
      ConformNumber: {
        Title: '再次確認你的號碼',
        SubTitle: '將發出你的驗證號碼',
        DefaultCountry: 'India',
        content: {
          Number: 'true',
          Password: false,
          PhoneNumberHolder: '電話號碼',
          PassHolder: '密碼'
        },
        tag: 'Login_Info',
        Button: '確認'
      },
      VerifyOtp: {
        Title: '驗證',
        SubTitle: '輸入收到的四位驗證碼',
        footer: '沒有收到驗證碼',
        opt: '秒內重新發出',
        content: {
          Otp: true
        },
        Button: '繼續'
      },
      resetPass: {
        Title: '輸入新密碼',
        SubTitle: '再次確認新密碼',
        content: {
          Password: true,
          PassHolder: '新密碼',
          ConfirmPass: '再次確認新密碼'
        },
        tag: 'Login_Info',
        Button: '完成'
      }
    },
    SliderImageData: {
      sliderButton: '你的最佳選擇!',
      sliderButtonLink: '',
    },
    Services: {},
    CardsData: {
      Title: '我們的服務範圍',
      RightText: '', //全部
      RightTextLink: '/service',
      ErrorLogs: {
        Title: 'Please Login',
        SubTitle: 'Please login to your account',
        Styling: {
          Icon: 'Error',
        }
      },
      cards: [
        {
          title: '電單車',
          subtitle: '買賣最適合你的車',
          bodycontent: 'KTM is one of the finest sports bikes in India. It has a powerful engine.',
          cardImage: 'https://wallpaperaccess.com/full/3460572.jpg',
          button: '尋找心儀的汽車',
          ButtonLink: '/product-detail',
          tags: 'Ride',
        },
        {
          title: '驗車',
          subtitle: '買賣最適合你的車',
          bodycontent: 'KTM is one of the finest sports bikes in India. It has a powerful engine.',
          cardImage: 'https://wallpaperaccess.com/full/2056566.jpg',
          button: '預約',
          tags: 'MOT',
          ErrorLogs: {
            Title: 'Please Login',
            SubTitle: 'Please login to your account',
            Styling: {
              Icon: 'Error',
            }
          },
          requestSuccess: {
            Title: 'Request Success',
            SubTitle: 'we will get back to you, shortly',
            Styling: {
              Icon: 'Error',
            }
          },
        },
        {
          title: '拖車服務',
          subtitle: '買賣最適合你的車',
          bodycontent: 'KTM is one of the finest sports bikes in India. It has a powerful engine.',
          cardImage: 'https://www.xtrafondos.com/wallpapers/resized/volante-de-bmw-m6-2327.jpg?s=large',
          button: '現在查看!',
          tags: 'Map',
        }
      ]
    }
  }
}
export default language